@import './variables.css';
@import './placeholder-loader.css';
@import 'handsontable/dist/handsontable.full.css';

@font-face {
    font-family: 'roboto';
    src: url('fonts/Roboto-Thin.ttf');
    src: url('fonts/Roboto-Thin.eot');
    src: url('fonts/Roboto-Thin.woff');
    font-weight: 100;
}

@font-face {
    font-family: 'roboto';
    src: url('fonts/Roboto-Light.ttf');
    src: url('fonts/Roboto-Light.eot');
    src: url('fonts/Roboto-Light.woff');
    font-weight: 300;
}
@font-face {
    font-family: 'roboto';
    src: url('fonts/Roboto-Regular.ttf');
    src: url('fonts/Roboto-Regular.eot');
    src: url('fonts/Roboto-Regular.woff');
    font-weight: 400;
}
@font-face {
    font-family: 'roboto';
    src: url('fonts/Roboto-Medium.ttf');
    src: url('fonts/Roboto-Medium.eot');
    src: url('fonts/Roboto-Medium.woff');
    font-weight: 500;
}
@font-face {
    font-family: 'roboto';
    src: url('fonts/Roboto-Bold.ttf');
    src: url('fonts/Roboto-Bold.eot');
    src: url('fonts/Roboto-Bold.woff');
    font-weight: 700;
}
@font-face {
    font-family: 'roboto';
    src: url('fonts/Roboto-Black.ttf');
    src: url('fonts/Roboto-Black.eot');
    src: url('fonts/Roboto-Black.woff');
    font-weight: 900;
}

body {
    font-family: 'roboto';
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: var(--primary-color);
    background-color: #ffffff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--primary-color);
}
h1 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 10px;
}
h2 {
    font-size: 17px;
    line-height: 23px;
    font-weight: 400;
    margin-bottom: 8px;
}
h3 {
    font-size: 15px;
}
h4 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}
h6 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}


/* Center div content vertically and horizontally */
.content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-control {
    color: var(--primary-color);
}
.form-control:focus {
    box-shadow: none;
}
.disable_resize {
    resize: none;
}

/*=============================================*/

.rightside_pageContent {
    position: relative;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.full_pageContent,
.rightside_pageContent > :not(hr) {
    margin: 15px;
}

/* Fixes workspace edit screen in the lab - otherwise very narrow */
.rightside_pageContent > .data-page {
    max-width: 100%;
}

.toggleTopOnScroll .workbench.show_sidebar .stickyTop,
.workbench.show_sidebar .rightside_pageContent,
.toolbar.show_sidebar .rightside_pageContent {
    padding-left: var(--sidemenu_width);
}

.toggleTopOnScroll .workbench.hide_sidebar .stickyTop,
.workbench.hide_sidebar .rightside_pageContent,
.toolbar .rightside_pageContent {
    padding-left: 80px;
}
.toggleTopOnScroll .workbench.show_sidebar .stickyTop,
.workbench.show_sidebar .rightside_pageContent,
.toolbar.show_sidebar .rightside_pageContent {
    padding-left: 225px;
}

@media screen and (max-width: 767px) {
    .toggleTopOnScroll .workbench.hide_sidebar .stickyTop,
    .workbench.hide_sidebar .rightside_pageContent,
    .toolbar .rightside_pageContent {
        padding-left: 0;
    }
    .custom_container {
        padding: 0px 0px;
    }
    .backbar {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.custom_container {
    flex: 1 1 1px; /* https://stackoverflow.com/a/52489012 */
}

.pad_tb10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.pad_tb14 {
    padding-top: 14px;
    padding-bottom: 14px;
}
.pad_tb15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pad_lr10 {
    padding-right: 10px;
    padding-left: 10px;
}
.pad_lr15 {
    padding-right: 15px;
    padding-left: 15px;
}
.pad_l15 {
    padding-left: 15px;
}
.pad_l30 {
    padding-left: 30px;
}

.pad_0 {
    padding: 0;
}
.pad_5 {
    padding: 5px;
}
.pad_10 {
    padding: 10px;
}
.pad_15 {
    padding: 15px;
}
.pad_top0 {
    padding-top: 0px;
}
.pad_top10 {
    padding-top: 10px;
}
.pad_top15 {
    padding-top: 15px;
}
.pad_bot10 {
    padding-bottom: 10px;
}
.pad_bot15 {
    padding-bottom: 15px;
}
.pad_bot25 {
    padding-bottom: 25px;
}

.pad_r0 {
    padding-right: 0;
}
.pad_r20 {
    padding-right: 20px;
}
.pad_r30 {
    padding-right: 30px;
}
/*--------------------------------------------*/
.m_0 {
    margin: 0;
}
.m_top4 {
    margin-top: 4px;
}
.m_top10 {
    margin-top: 10px;
}
.m_top20 {
    margin-top: 20px;
}
.m_bot0 {
    margin-bottom: 0;
}
.m_bot10 {
    margin-bottom: 10px;
}
.m_bot20 {
    margin-bottom: 20px;
}
.m_left15{
    margin-left: 15px;
}
.m_right10{
    margin-right: 10px
}
/*--------------------------------------------*/
.min_width300{
    min-width: 300px;
}
/*=============================================*/
.fullScreenSize {
    width: 100%;
    height: 100vh;
    background: #fff;
}

.mw-auto {
    min-width: auto !important;
}
.RetryErrorBox {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.RetryErrorBox img {
    width: 82px;
    /* height: 55px; */
    object-fit: contain;
}
.RetryErrorBox h1 {
    margin: 25px 0 0;
    font-size: 30px;
    line-height: 22px;
    font-weight: 500;
    color: #ff9e00;
}
.RetryErrorBox h2 {
    margin: 8px 0 0;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: var(--primary-color);
}
.RetryErrorBox button {
    margin-top: 26px;
    background: #f29048;
    border: 1px solid #f29048;
    border-radius: 4px;
    padding: 6px 10px 5px;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    width: 115px;
}

/*=============================================*/
.loader_parent {
    width: 100%;
    height: 100vh;
    /* position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    background: #fff;
}
.loaderfadeOut {
    transition: opacity 0.8s ease-out;
    opacity: 0;
}

.loaderfadeOut .loader_parent {
    transition: visibility 0.8s ease-out;
    visibility: hidden;
}
.loader {
    position: absolute;
    height: 100px;
    width: 100px;
    object-fit: contain;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.dataLoader_parent {
    height: 100%;
    background-color: #fff;
}

.loaderfadeOut .dataLoader_parent {
    transition: visibility 0.8s ease-out;
    visibility: hidden;
}
.dataLoader {
    width: 100%;
}

/* ------------------------------------ */
.text-muted {
    color: #989898 !important;
}

/*=============================================*/

/*===============================================
Start Common Form Style   =================*/

/* --------------------------------- */
.customSelectbox select {
    width: auto;
    padding: 0 30px 0 10px;
    height: auto;
    color: #a7a7a7;
    font-size: 13px;
    line-height: 17px;
    border: none;
    float: right;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
}
.customSelectbox select:focus {
    border: none;
    outline: none;
    background: transparent;
}
.customSelectbox:after {
    content: '\25BE';
    color: #a7a7a7;
    display: inline-block;
    position: absolute;
    z-index: 11;
    right: 0px;
    top: 0px;
    pointer-events: none;
    font-size: 16px;
    transform: scaleX(1.6);
}
/* --------------------------------- */
.spinner_btnHolder {
    min-width: 130px;
    text-align: center;
}
.spinner-border {
    border: 3px solid #f29048;
    border-right-color: transparent;
    height: 35px;
    width: 35px;
}
.validation_msg {
    color: red;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 15px;
}
.form-group {
    margin-bottom: 15px;
}
.form-group .form-control {
    border: 1px solid #707070;
    margin-top: 6px;
}
.form-group .form-control:focus {
    box-shadow: none;
    border-color: #707070;
}
.form-group label {
    font-size: 15px;
    line-height: 22px;
    color: var(--primary-color);
    margin-bottom: 0;
}
.text-muted {
    color: #989898 !important;
    margin-top: 2px;
}
.form-text {
    font-size: 12px;
    line-height: 15px;
    margin-top: 5px;
}
.f-16-l-20 {
    font-size: 16px;
    line-height: 18px;
}

.btn-warning {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    background: #f29048;
    border: 1px solid #f29048;
    color: #fff;
    padding: 7px 30px 4px;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    background: #f29048;
    border-color: #f29048;
    color: #fff;
}

.btn-danger {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
    padding: 7px 30px 4px;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

/*  End Common Form Style   =====================
===============================================*/
/*===============================================
  Start Modal Style   =========================*/
.modal .modal-title.h4 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
}
.modal-body {
    font-size: 15px;
    line-height: 22px;
}
.modal-footer .btn {
    font-size: 14px;
    line-height: 22px;
    padding: 0.375rem 0.75rem;
}
.modal-footer .btn-secondary:not(:disabled):not(.disabled).active:focus,
.modal-footer .btn-secondary:not(:disabled):not(.disabled):active:focus,
.modal-footer .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}
/*  End Modal Style   ===========================
===============================================*/

/*===============================================
  Start new insight popup Style   =============*/
.newInsightBox {
    background: #fff;
    padding: 12px 10px;
    border-radius: 20px;
    position: fixed;
    z-index: 9;
    top: 50px;
    left: 0;
    right: 0;
    width: 140px;
    margin-left: auto !important;
    margin-right: auto !important;
    -webkit-box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.15);
}
/*  End new insight popup Style   ===============
===============================================*/

.topbar > form > .input-group {
    border: 1px solid #c5c5c5;
    border-radius: 4px;
}
.topbar > form > .input-group input.form-control {
    border-width: 0;
}
.topbar > form > .input-group button.input-group-append {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0;
}

.topbar > form > .input-group button.input-group-append > img {
    width: 14px;
}
.backbar {
    font-size: 13px;
    line-height: 16px;
    color: #a7a7a7;
    margin: 15px 0;
    /* padding-left: 18px; */
    padding-right: 15px;
}
.backbar img {
    width: 10px;
    height: 15px;
    margin-right: 3px;
}
.backbar span {
    margin: auto 0;
}
.breadcrumb {
    padding: 0;
    margin: 0;
    background: transparent;
}
.breadcrumb span {
    color: #a7a7a7;
}
.breadcrumb:hover {
    text-decoration: none;
}
.cursor-pointer {
    cursor: pointer;
}
.autoHeight {
    height: auto;
}
.width100 {
    width: 100px;
}
.dataset-card .rightSide_verticalDots {
    top: 0;
}
.rightSide_verticalDots {
    position: absolute;
    right: 0;
    z-index: 2;
}

/*==============================================*/
.custom_alertbox .alert {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);

    border: 1px solid #dedede;
    font-size: 15px;
    line-height: 19px;
    margin: 0;
}
.alert-warning {
    border: 1px solid #f29048;
    background: #fff7f2;
    color: #f29048;
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    padding: 10px;
}

.input_with_copy_icon {
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px;
    margin-top: 11px;
    cursor: pointer;
}
.input_with_copy_icon .form-control {
    border: none;
    padding: 0 10px 0 0;
    margin: 0;
    height: auto;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
}
.input_with_copy_icon .input-group-append img {
    height: 20px;
    width: 17px;
    object-fit: contain;
}
/*================================================
Start Graph Pages ==============================*/
.selectViewButton .view_icon {
    background: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
    padding: 5px 10px;
}
.selectViewButton .view_icon svg {
    height: 19px;
    fill: #a7a7a7;
}
.selectViewButton .view_icon.active svg {
    fill: #7c7c7c;
}
.fullWidth.largCardView.custom_container,
.fullWidth.listView.custom_container {
    padding-top: 5px;
}
.fullWidth.largCardView .dataset-card {
    min-height: 50vh;
}

.news-card {
    width: 90%;
    margin: 0 auto;
}
.dataset-card {
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;
}
.subtitle {
    min-height: 28px;
    font-size: 13px;
    line-height: 17px;
    color: #646464;
}
.gridbox {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0;
}
.gridbox:hover{
    border-color: #545454;
}
.gridbox h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}

.dataset-card-details {
    padding: 10px;
    width: 100%;
    border-top-color: #ddd;
    border-top-style: solid;
    border-top-width: thin;
}

.listView .dataset-card-details {
    border-top: none;
}

.fullWidth.gridview {
    gap: 12px;
}

.gridview .gridbox {
    width: calc(33% - 6px);
}

.gridbox .detailslink:hover {
    text-decoration: none;
}
.gridbox .detailslink:hover h2 {
    color: var(--primary-color);
}
.gridbox .detailslink:hover .text-muted p {
    color: #a5a5a5 !important;
}
.graph_holder {
    background: #ffffff;
    overflow: hidden;
    border-radius: inherit;
    width: 100%;
    margin: 15px auto
}

.dataset-card .graph_holder {
    margin: 0;
}

.gridview .gridbox .graph_holder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.graph_detail {
    width: 100%;
    margin-top: 0px;
    min-height: 42px;
    padding-left: 35px;
    background: url(./../images/logo.svg) no-repeat;
    background-size: 28px 28px;
}
.gridview .graph_detail h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    width: 95%;
    margin-bottom: 0px;
}
.gridbox .text-muted {
    color: #a7a7a7 !important;
}
.gridbox .text-muted p {
    /* display: inline-block; */
    float: left;
    margin-bottom: 0;
    margin-top: 0;
}
.gridbox .text-muted p:not(:last-child) {
    width: 100%;
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* common in view */
.fullWidth.largCardView .gridbox .graph_detail h2,
.fullWidth.listView .gridbox .graph_detail h2 {
    top: 0px;
    position: relative;
    margin-bottom: 0px;
}
.fullWidth.listView .gridbox .graph_detail p {
    top: 0px;
    margin-bottom: 5px;
}
/* common in view */

/* start graph list view */
.listView .gridbox .detailslink {
    display: flex;
}
.listView .gridbox .detailslink .graph_holder {
    height: auto;
    max-height: 250px;
}
.fullWidth.listView .gridbox .detailslink {
    display: block;
    position: relative;
}

.fullWidth.listView .gridbox .detailslink:hover:before {
    bottom: 15px;
}
@media screen and (max-width: 767px) {
    /* common in list and largeCard  view */
    .fullWidth.largCardView .gridbox .graph_detail,
    .fullWidth.listView .gridbox .graph_detail {
        width: 100%;
        background-position: 0 0;
        padding-left: 55px;
        margin-top: 0;
    }
    /* common in list and largte view */

    .selectViewButton .view_icon svg {
        height: 15px;
    }
    .fullWidth.listView .gridbox .graph_holder {
        display: none;
    }
    .fullWidth.listView.custom_container {
        padding-top: 10px;
    }
    .gridbox {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .fullWidth.listView .gridbox {
        max-height: 250px;
    }
    .fullWidth.listView .gridbox .graph_detail h2 {
        min-height: 44px;
    }
}
/* end graph list view */
.fullWidth.largCardView .graph_holder {
    height: 100%;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 20px;
}
/*==================================
 Start News Page =================*/
.fullWidth.largCardView.singleView.custom_container {
    padding-top: 28px;
}
.largCardView.singleView .gridbox {
    padding: 0;
}
.largCardView.singleView .usercomment {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #646464;
}
.largCardView.singleView .usercomment b {
    font-weight: 500;
}
.largCardView.singleView .usercomment .text-muted {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #a5a5a5;
}
.largCardView.singleView .usercomment.toggleHeight {
    /* max-height: 50px; */
    overflow: hidden;
    position: relative;

    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
}
.largCardView.singleView .usercomment.toggleHeight .readmore {
    text-decoration: underline;
    background: #fff;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    padding: 0 10px;
}
.largCardView.singleView .graph_holder {
    padding: 10px !important;
    margin-top: 0;
}
.largCardView.singleView .borderedSection {
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 15px;
    overflow: hidden;
}
.largCardView.singleView .divider {
    margin: 27px 0 23px;
    height: 1px;
    display: block;
    background: #cecece;
}
.largCardView.singleView .gridbox:last-child .divider {
    background: transparent;
    margin-bottom: 0;
}

/* End News Page ================
==================================*/

.loadMoreDatasetButton {
    text-align: center;
    padding: 30px;
}
.loadMoreDatasetButton.spinner-border {
    animation: spinner 1s linear infinite;
}
.loadMoreDatasetButton button.spinner-border {
    background-color: transparent;
    border: 3px solid #f29048;
    border-top-color: transparent;
    height: 35px;
    width: 35px;
    padding: 0;
    display: inline-block;
    border-radius: 30px;
}
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 767px) {
    .graph_detail {
        margin-top: 15px;
    }
    /*==================================
     Start News Page =================*/
    .fullWidth.largCardView.singleView.custom_container {
        padding-top: 22px;
    }
    /* .largCardView.singleView .gridbox {
        padding: 0;
    } */

    .largCardView.singleView .detailslink {
        /* padding-left: 0; */
        padding: 15px;
    }
    .largCardView.singleView .graph_holder {
        padding: 5px !important;
        /* margin-top:0; */
    }
    .largCardView.singleView .divider {
        margin: 17px 0 20px;
        height: 3px;
    }

    /* End News Page ================
    ==================================*/
}

@media screen and (min-width: 767px) {
    /*==================================
     Start News Page =================*/
    .fullWidth.largCardView.singleView.custom_container {
        padding-top: 30px;
        justify-content: center;
    }
    .fullWidth.largCardView.singleView .gridbox {
        flex: unset;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
        display: block;
    }
    .largCardView.singleView .graph_detail {
        min-height: 54px;
        padding-left: 70px;
        background-size: 54px 54px;
    }
    .largCardView.singleView .usercomment {
        font-size: 22px;
        line-height: 34px;
    }

    .largCardView.singleView .usercomment .text-muted {
        font-size: 18px;
        line-height: 34px;
    }
    .largCardView.singleView .usercomment.toggleHeight {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        /* max-height: 66px; */
    }
    .largCardView.singleView .graph_holder {
        padding: 17px !important;
    }
    .largCardView.singleView .borderedSection .graph_detail {
        padding-left: 77px;
    }
    .largCardView.singleView .divider {
        margin: 72px 0 30px;
    }
    /* End News Page ================
    ==================================*/
}

/*  End Graph Pages ============================
================================================*/

/* ================================================
    Start Slider Style ==========================*/
.roundedListItemSlider_holder.SliderWith_rightSide_verticalDots {
    padding: 0 34px 0 15px;
}
.roundedListItemSlider_holder .rightSide_verticalDots {
    right: 15px;
}
.roundedListItemSlider_holder .rightSide_verticalDots {
    top: 16px;
}
.roundedListItemSlider_holder .roundedSliderItem {
    list-style: none;
    float: left;
    text-align: center;
}
.roundedListItemSlider_holder .roundedSliderItem .item_icon {
    max-height: 42px;
    min-height: 42px;
    display: unset;
}
.roundedListItemSlider_holder .roundedSliderItem .channel_name {
    padding: 5px 0 0;
    margin: 0;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.roundedListItemSlider_holder .roundedSliderItem.disableChannel .item_icon {
    filter: grayscale(100%);
    opacity: 0.4;
}
.roundedListItemSlider_holder .roundedSliderItem.disableChannel:hover .item_icon {
    filter: grayscale(0%);
    opacity: 1;
}

/* markdown editor on dataset */

.dataset-editor {
    padding-bottom: 30px;
}

.wmde-markdown h1 {
    border-bottom: none !important;
    padding-bottom: 0.3em;
}

.wmde-markdown h2 {
    border-bottom: none !important;
    padding-bottom: 0.3em;
}

.desceditor .w-md-editor-text-pre code[class*="language-"], pre[class*="language-"] {
    margin: 0 !important;
    border: 0 !important;
    background: none !important;
    box-sizing: inherit !important;
    display: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: inherit !important;
    -webkit-font-variant-ligatures: inherit !important;
    font-feature-settings: inherit !important;
    font-variant-ligatures: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
    tab-size: inherit !important;
    text-indent: inherit !important;
    text-rendering: inherit !important;
    text-transform: inherit !important;
    white-space: pre-wrap !important;
    overflow-wrap: inherit !important;
    word-break: inherit !important;
    word-break: normal !important;
    padding: 0 !important;
    line-height: 18px !important;
}

.desceditor .w-md-editor-text-input {
	  line-height: 18px !important;
}

.editorActionButtons {
    padding-top: 10px;
}
.editorActionButtons button.btn-warning {
    padding: 6px 15px 5px;
}
.editorActionButtons button.btn-secondary {
    background: transparent;
    padding: 0;
    border: none;
    height: 35px;
}
.editorActionButtons .btn-secondary:not(:disabled):not(.disabled).active,
.editorActionButtons .btn-secondary:not(:disabled):not(.disabled):active,
.editorActionButtons .show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}
.editorActionButtons button.btn-secondary img {
    height: 100%;
    padding: 8px 10px;
}
/* ----------- share popup slider ----------- */
.slider_shareIcons .slick-track {
    display: flex;
    min-width: 100%;
}
.slider_shareIcons {
    padding: 0 25px;
}
.slider_shareIcons .slick-arrow {
    height: 100%;
    background: #fff;
    width: 25px;
    z-index: 1;
}
.slider_shareIcons .slick-arrow:before {
    top: auto !important;
    font-size: 20px !important;
    -webkit-text-stroke: 0 !important;
    transform: scaleX(2) !important;
    margin-top: -20px;
    color: #5e5e5e !important;
}
.slider_shareIcons .slick-arrow.slick-next:before {
    right: -8px !important;
}
.slider_shareIcons .slick-arrow.slick-prev:before {
    left: -8px !important;
}
.slider_shareIcons .item {
    padding: 0 12px 10px;
    text-align: center;
}
.slider_shareIcons .item .btn-link {
    padding: 0;
    border: none;
    font-size: 14px;
    line-height: 19px;
    color: #373737;
}
.slider_shareIcons .item .btn-link {
    text-decoration: none;
}
.slider_shareIcons .item img {
    object-fit: contain;
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 10px;
    display: inline-block;
}
/* --------------------- */
.sharePopup {
    margin: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.sharePopup .modal-body {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.sharePopup.show .modal-body {
    opacity: 1;
}
.sharePopup .modal-header {
    padding: 25px 30px 0;
    border-bottom: none;
}
.sharePopup .modal-header .modal-title.h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #373737;
}
.sharePopup .modal-header .close span {
    display: none !important;
}
.sharePopup .modal-header .close {
    position: relative;
}
.sharePopup .modal-header .close:before,
.sharePopup .modal-header .close:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 5px;
    height: 20px;
    width: 20px;
    border: solid #000;
}
.sharePopup .modal-header .close:before {
    border-width: 0 2px 0 0;
    transform: rotate(45deg);
    right: 15px;
}
.sharePopup .modal-header .close:after {
    border-width: 0 0 0 2px;
    transform: rotate(-45deg);
    right: 2px;
}
.modal-backdrop {
    z-index: 9999;
}
.modal {
    z-index: 99999;
}
.sharePopup .modal-body {
    padding: 18px;
}
.sharePopup .modal-content {
    border: none;
    border-radius: 0;
}
.sharePopup.watchPopup .modal-dialog {
    min-width: 300px;
}
.sharePopup.watchPopup .modal-header {
    padding: 15px 20px 0;
    border-bottom: none;
}
.sharePopup.watchPopup .modal-body {
    padding: 15px 20px;
}
.sharePopup.watchPopup .modal-body .message {
    padding: 0px 0px 15px 0px;
}
.sharePopup.watchPopup .slider_shareIcons {
    padding: 0;
}
.sharePopup.watchPopup .slider_shareIcons .item {
    padding: 0 6px 10px;
}
@media screen and (min-width: 576px) {
    .modal-dialog {
        min-width: 500px;
    }
}
/* --------------------- */
.tosterMessage {
    position: fixed;
    z-index: 99999;
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #febc41;
    color: #fff;
    border: none;
    border-radius: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0 !important;
}
@media screen and (max-width: 767px) {
    .sharePopup .modal-dialog {
        max-width: 100%;
        width: 100%;
        margin: 0;
    }
    .sharePopup {
        align-items: flex-end;
        z-index: 9999;
    }
    .slider_shareIcons {
        padding: 0;
    }
    .slider_shareIcons .slick-arrow {
        display: none !important;
    }
}

/* ----------- channel list slider ----------- */
.roundedListItemSlider {
    padding: 0 57px;
    overflow: hidden;
}
.slick-slider.roundedListItemSlider .slick-arrow {
    background: #a7a7a7;
    color: #fff;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    top: 0px;
    transform: translate(0px, 0px);
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.slick-slider.roundedListItemSlider .slick-arrow:before {
    top: 9px;
    margin: auto;
    color: #fff;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.slick-slider.roundedListItemSlider .slick-arrow.slick-disabled,
.slick-slider.roundedListItemSlider .slick-arrow.slick-disabled:before {
    opacity: 0.3;
    cursor: default;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.slick-slider.roundedListItemSlider .slick-arrow.slick-prev:before {
    left: 0;
}
.slick-slider.roundedListItemSlider .slick-arrow.slick-next:before {
    right: 0;
}
/* -------------------------------------------- */
/* ------------- button list ------------- */
.slick-slider .slick-arrow:before {
    color: #a7a7a7;
    opacity: 1;
    position: absolute;
    z-index: 1;
    top: -2px;
    font-size: 20px;
    -webkit-text-stroke: 1px;
    transform: scaleX(1.8);
}
.slick-slider .slick-arrow.slick-prev:before {
    content: '\3008';
    left: -3px;
}
.slick-slider .slick-arrow.slick-next:before {
    content: '\3009';
    right: -3px;
}
.slick-slider .slick-arrow.slick-prev {
    left: 0;
}
.slick-slider .slick-arrow.slick-next {
    right: 0;
}

.slick-slider .slick-arrow.slick-disabled:before {
    color: #dedede;
}

.slick-arrow:before {
    opacity: 1 !important;
}
/* ------------------------------------------- */
.buttonListSlider {
    padding: 10px 35px;
    overflow: hidden;
}
.slick-slider.buttonListSlider .slick-slide {
    padding: 0 10px 0 0;
}
.slick-slider.buttonListSlider .slick-track {
    display: flex;
    margin-left: 0;
}
.buttonListItem {
    background: transparent;
    border: 1px solid #a7a7a7;
    color: #cbcbcb;
    border-radius: 4px;
    font-size: 17px;
    line-height: 21px;
    font-weight: 400;
    padding: 8px 15px 8px;

    white-space: nowrap;
}
.buttonListItem.active {
    background: #fdbb41;
    border-color: #fdbb41;
    color: #fff;
}

/*  End Slider Style ============================
================================================*/

/*===============================================
    Start channel list Style ==================*/
.channel_row {
    padding: 7px 10px;
}
.channel_row .channel_icon,
.channel_row .channel_details,
.channel_row .channel_reminder {
    float: left;
}

.channel_row .channel_icon {
    width: 42px;
}
.channel_row .channel_details {
    width: calc(100% - 66px);
    padding: 0 10px;

    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
}
.channel_row .channel_reminder {
    width: 24px;
    color: #a7a7a7;
    font-size: 18px;
    text-align: center;
}
.channel_row .channel_icon img {
    width: 42px;
    height: 42px;
    object-fit: contain;
}
.channel_row .channel_details .description {
    font-size: 14px;
    line-height: 19px;
    color: #9a9a9a;
}
/*  End channel list Style =======================
================================================*/

/*===============================================
    Start library page Style ==================*/
.library_desc {
    font-size: 13px;
    line-height: 17px;
}
/*  End library page Style =======================
================================================*/

/*===============================================
    Start inbox page Style ====================*/

.inbox_page .channel_list {
    /* padding: 0 10px 0 20px; */
    padding: 0 5px;
}
.inbox_page .channel_row {
    /* padding: 14px 0;
    border-bottom: 1px solid #DEDEDE; */
    position: relative;
    padding: 0;
}
.inbox_page .channel_row .channel_icon,
.inbox_page .channel_row .channel_details {
    cursor: pointer;
}
.inbox_page .channel_row.read .channel_details {
    font-weight: 400;
}
.inbox_page .channel_row.unread .channel_details {
    font-weight: 500;
}
.inbox_page .channel_row .pad_tb14,
.inbox_page .swipeable-list-item__content > div {
    display: flex;
    /* align-items: center; */
}
.inbox_page .channel_row .pad_tb14 .channel_details,
.inbox_page .swipeable-list-item__content > div .channel_details {
    align-self: center;
}
.inbox_page .dayDivider {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    border-top: 1px solid #dedede;
    padding: 22px 0 10px;
}
.inbox_page .channel_list .channel_row:first-child .dayDivider {
    border-top: none;
}
.inbox_page .channel_icon {
    position: relative;
}
.inbox_page .channel_icon img {
    width: 42px;
    height: 42px;
    background-color: #efefef;
    padding: 10px;
    object-fit: contain;
}
.inbox_page .channel_details {
    width: calc(100% - 136px);
}
.inbox_page .channel_graph {
    width: 94px;
    position: relative;
    min-height: 42px;
}
.inbox_page .channel_graph img {
    width: 84px;
    height: 50px;
    object-fit: contain;
    position: relative;
    background: #f7f7f7;
}
.inbox_page .channel_graph .rightSide_verticalDots {
    /* top: calc(50% - 12px); */
    margin-right: 10px;
    padding: 0 10px;
}
/* .inbox_page.rightSide_verticalDots_dropdownlist {
    margin-top: 84px;
    margin-right: -4px;
} */
.inbox_page.rightSide_verticalDots_dropdownlist {
    padding: 5px 20px;
}
.inbox_page.rightSide_verticalDots_dropdownlist .list-item {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
}
.inbox_page.rightSide_verticalDots_dropdownlist .list-item .icon_holder svg {
    height: 22px;
    width: 20px;
}
.inbox_page.rightSide_verticalDots_dropdownlist .list-item .icon_holder {
    width: 35px;
}
.inbox_page.rightSide_verticalDots_dropdownlist .list-item .list-item-name {
    width: calc(100% - 35px);
}
.inbox_page.rightSide_verticalDots_dropdownlist.lastpopover {
    margin-top: -90px;
    /* margin-right: 4px; */
}

.inbox_page .swipeable-list-item__content > div {
    width: 100%;
    padding: 14px 0;
}
.swipeDelete {
    background: #e00000;
    width: 110px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.swipeDelete svg {
    fill: #fff;
}
/* ================================================== */
.rightSide_verticalDots_dropdownlist {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);

    padding: 5px 16px;
    border-radius: 4px;
    right: 0;
    background: #fff;
    z-index: 2;
    max-width: 80%;
}
.rightSide_verticalDots_dropdownlist .list-item {
    padding: 11px 0;
    font-size: 14px;
    line-height: 16px;
}
.rightSide_verticalDots_dropdownlist .icon_holder {
    width: 43px;
    padding-right: 14px;
}
.rightSide_verticalDots_dropdownlist .icon_holder svg {
    width: 100%;
    height: 19px;
}
.rightSide_verticalDots_dropdownlist .list-item-name {
    width: calc(100% - 43px);
}
/*  End inbox page Style =========================
================================================*/

/*=================================================
    Start dataset page Style ====================*/
.dataset-title-container {
    display: flex;
    justify-content: space-between;
}
.dataset-title-container h2 {
    margin-bottom: 0;
}
.dataset-title-container .text-muted p {
    margin-bottom: 0;
}
.action_list {
    flex-grow: 1;
    scale: 0.9;
    max-width: min-content;
    color: black;
    font-size: 0.875rem;
    font-weight: 400;
    padding-bottom: 20px;
}
.dataset-action {
	  padding: 0 10px;
}
.dataset_page .dataset-assistant .comments {
    padding: 0;
}
.dataset_page .dataset-assistant .alerts {
    padding: 15 0 0 0;
}
.dataset_page .dataset-assistant .related {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.dataset_page .related .graph_detail {
    padding-left: 30px;
}
.dataset_page .graph_detail {
	  padding-left: 50px;
}
.dataset_page .gridbox .title_showmore_link h2 {
    width: calc(100% - 60px);
    word-break: break-word;
}
.dataset_page .gridbox .title_showmore_link .showmore_link {
    width: 60px;
    color: #a5a5a5;
    margin-bottom: 12px;
}
.dataset_page .gridbox .title_showmore_link .showmore_link:hover {
    text-decoration: none;
    color: #a5a5a5;
}
.dataset_page .gridbox .text-muted.position-relative {
    left: 0;
    bottom: 0;
}
.datasetContent {
    display: flex;
}
.datasetContent-Dataset {
    flex-grow: 1;
}
.datasetContent-DatasetAssistant {
    max-width: 30%;
}
.datasetDesc.show-more {
    height: 250px;
    overflow: hidden;
}
.datasetDesc .lessMoreBtn {
    width: 50px;
    text-align: right;
    padding-top: 2px;
}
.datasetDesc .markdown-content {
    width: calc(100% - 50px);
}
.datasetDesc .markdown-content h1 {
    font-size: 2em;
    font-weight: 600;
    border-bottom: none;
    padding-bottom: 0.3em;
}
.datasetDesc .markdown-content h2 {
    font-size: 1.5em;
    font-weight: 600;
	  border-bottom: none;
    padding-bottom: 0.3em;
}
.datasetDesc .markdown-content h3 {
    font-size: 1.25em;
    font-weight: 600;
}
.datasetDesc .markdown-content h4 {
    font-size: 1em;
    font-weight: 600;
}
.datasetDesc .markdown-content code {
    color: inherit;
    font-size: inherit;
    /* font-weight: 500;
    font-family: inherit; */
}
/* START List View */

.dataset_page .listView.custom_container {
    padding-left: 10px;
    padding-right: 10px;
}
.dataset_page .listView .gridbox .related-dataset-card {
    display: block;
    width: 100%;
}
.dataset_page .listView .gridbox {
    max-width: 100%;
    flex: 0 0 100%;
    /* max-height: 250px; */
    /* margin-bottom: 20px; */
    margin-bottom: 15px;
    overflow: hidden;
    padding: 0;
}
.dataset_page .listView .gridbox .graph_holder,
.dataset_page .listView .gridbox .graph_detail {
    display: inline-block;
}
.dataset_page .listView .gridbox .graph_holder img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.dataset_page .listView .gridbox .graph_detail {
    background: none;
    padding-left: 15px;
    margin-top: 0;
    position: relative;
    height: 100%;
}
.dataset_page .listView .gridbox .graph_detail .text-muted {
    position: relative;
    bottom: 0;
    left: 0;
    font-size: 12px;
    line-height: 17px;
}

/* END List View */

.alert .raised {
    font-size: 15px;
    line-height: 17px;
    color: #b7b7b7;
    margin: 0;
}

.alert .detail {
    margin: 0;
}

.action_list svg,
.action_list i {
    margin-bottom: 5px;
}
.action_list svg {
    height: 26px;
}
.action_list i {
    font-size: 22px;
    line-height: 26px;
}
.action_list i.active {
    color: #faa128;
}
.action_list span.active {
    color: #faa128;
}
.action_list .disable {
    pointer-events: none;
}

.add_comment img {
    height: 42px;
    width: 42px;
    object-fit: cover;
}
.add_comment .form-group {
    margin-bottom: 0;
    width: calc(100% - 42px);
}
.add_comment .form-control {
    padding: 0 0 0 15px;
    margin: 2px 0;
    border: none;
}
.add_comment .form-control.pad_0 {
    padding: 0;
}
.add_comment .form-control::-webkit-input-placeholder {
    /* Edge */
    color: #a5a5a5 !important;
}
.add_comment .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a5a5a5 !important;
}
.add_comment .form-control::placeholder {
    color: #a5a5a5 !important;
}

.add_comment .rta .form-control.pad_tb8 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.add_comment .rta .rta__list {
    padding: 12px;
    border-radius: 3px;
    box-shadow: 0 8px 16px -4px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
    border: none;
    margin-top: 10px;
}
.add_comment .rta .rta__list .rta__item:not(:last-child) {
    border-bottom: none;
}

.add_comment .rta .rta__list .rta__entity {
    padding: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
}
.add_comment .rta .rta__list .rta__entity.rta__entity--selected {
    background: rgba(9, 30, 66, 0.04);
    color: #000;
    /* border-radius: 4px; */
}

.comment .comment_content span {
    font-weight: 700;
}
.comment .comment_content {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    word-break: break-word;
    overflow-wrap: break-word;
    width: calc(100% - 90px);
}
.comment .duration {
    font-size: 15px;
    line-height: 17px;
    color: #b7b7b7;
    width: 70px;
}
.comment_action {
    padding: 10px 0 0 3px;
}
.comment_action i {
    font-size: 18px;
    margin-bottom: 0;
}
.comment_action span {
    padding: 0 12px 0 5px;
    font-size: 13px;
    line-height: 18px;
}
.comment .sub_comment {
    padding-left: 35px;
    padding-top: 10px;
}

.viewmore_replies .triangle {
    position: relative;
    height: 5px;
    width: 5px;
    display: inline-block;
    margin-right: 10px;
    top: -2px;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #f29048 transparent transparent transparent;
}
.viewmore_replies.hide .triangle {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #f29048 transparent;
}
.viewmore_replies {
    background: transparent;
    border: none;
    color: #f29048;
}
/*  End dataset page Style =========================
==================================================*/

/*==================================================
    Start library page Style =====================*/
.library_page {
    padding: 0 10px;
}
.library_page.pad_r0 {
    padding-right: 0px;
}
.story_list {
    font-size: 14px;
    line-height: 17px;
}
.story_list a.story_box {
    padding: 15px 15px 15px 0px;
    border-bottom: 1px solid #dedede;
    color: var(--primary-color);
}
.story_list a.story_box:hover {
    text-decoration: none;
}
.story_list a.story_box:hover p {
    color: var(--primary-color);
}

.story_list .text-muted {
    font-size: 12px;
    color: #a5a5a5;
}
.story_list a.story_box .rightSide_verticalDots {
    top: 15px;
}
.library_page_divider {
    padding: 0 30px;
}
/* ------------------------------ */
.recentLibrarySlider_holder {
    padding-left: 20px;
}
.recentLibrarySlider {
    /* margin: 0 -7px; */
    padding-top: 15px;
    /* width: calc(100% + 7px); */
    padding: 10px 0;
    margin-right: -50px;
}
.recentLibrarySlider .slick-track {
    margin-left: 0;
}
.recentLibrarySlider .slick-arrow {
    display: none !important;
}
.recentLibrarySlider .slick-slide {
    /* width: 140px; */
    padding-right: 14px;
}
.recentLibrarySlider .gridbox {
    width: 100%;
    padding: 0;
}
.recentLibrarySlider .gridbox .rightSide_verticalDots {
    right: 3px;
}
.recentLibrarySlider .graph_detail {
    padding: 0 12px 0 0;
    background: none;
}
.recentLibrarySlider .graph_detail h2 {
    font-size: 15px;
    line-height: 18px;
    word-break: break-word;
}
/*  End library page Style =========================
==================================================*/

/*==================================================
    Start invite page Style =====================*/
.inviteList {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}

.inviteList .status {
    text-transform: lowercase;
}
.inviteList .status::first-letter {
    text-transform: uppercase;
}
.inviteList .status .revoked {
    color: #C40A0A;
}
.inviteList .status .pending {
    color: #f29048;
}
.inviteList .status .accepted {
    color: #019612;
}
.inviteListRow.rowHead {
    font-weight: 500;
    border-top: 1px solid #d0d0d0;
}
.inviteList .inviteListRow {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #d0d0d0;
}
.inviteList .inviteListRow:not(.rowHead):hover {
    -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    -moz-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.inviteButtons {
    text-align: right;
}
.inviteButtons .replaceByLoader button {
    opacity: 0;
}
.inviteButtons .inviteButtonHolder {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}
.inviteList .inviteButtonHolder:last-child {
    margin-right: 0px;
}
.inviteList .btn-warning {
    padding: 1px 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 4px;
}
.inviteButtons .spinner_btnHolder {
    position: absolute;
    z-index: 9;
    min-width: auto;
    top: 0;
    left: 0;
    right: 10px;
    bottom: 0;
}
.inviteButtons .spinner_btnHolder .spinner-border {
    height: 26px;
    width: 26px;
}

@media screen and (max-width: 767px) {
    .inviteList .inviteListRow {
        display: block;
    }
    .inviteList .date {
        margin-top: 5px;
    }
    .inviteList .status {
        text-align: right;
    }
    .inviteList .inviteButtons {
        text-align: left;
        margin-top: 10px;
    }
}
/*  End invite page Style =========================
==================================================*/

@media screen and (max-width: 767px) {
    .newInsightBox {
        top: 70px;
    }

    .custom_container.gridview {
        padding-left: 0;
        padding-right: 0;
    }

    .dataset-card {
        min-height: min-content;
    }

    .gridview .graph_detail {
        padding-left: 70px;
        padding-right: 15px;
        background-position: 15px 0;
    }

    .gridbox .text-muted {
        position: relative;
        bottom: 0;
        left: 0;
    }

    /* start for explore page */
    .explore_page .subtitle {
        padding: 0;
    }
    /* end for explore page */

    /* start for inbox page */
    .inbox_page .channel_list {
        padding: 0;
    }
    .inbox_page .channel_row {
        /* padding: 11px 0; */
        border-bottom: none;
    }
    .inbox_page .channel_icon:before {
        left: -12px;
    }
    /* end for inbox page */

    /* start for library page */
    .library_page {
        padding: 0 !important;
    }
    .library_page_divider {
        padding: 0;
    }
    .library_page .story_list a.story_box {
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: none;
    }
    .story_list .story_box {
        padding: 11px 15px 11px 0px;
        border-bottom: none;
    }
    /* end for library page */

    /* start for channel page */
    .channel_page .channel_row {
        padding-left: 0;
        padding-right: 0;
    }
    .channel_page .channel_list {
        margin: 0 -4px;
    }
    /* end for channel page */

    /* start for dataset page */
    .dataset_page .custom_container.mobview_dataset,
    .dataset_page .mobview_dataset .gridbox {
        padding: 0;
    }
    .dataset_page .mobview_dataset .graph_detail {
        padding-left: 65px;
    }
    .dataset_page .mobview_dataset .dataset-assistant {
        padding-bottom: 60px;
    }
    .dataset_page .mobview_dataset .custom_container.listView .gridbox {
        margin-bottom: 20px;
    }
    .dataset_page .mobview_dataset .listView .graph_detail {
        padding-left: 15px;
    }
    .dataset_page .mobview_dataset .shownore_link {
        color: #a5a5a5;
        padding: 12px 20px 25px;
        display: block;
    }
    .dataset_page .mobview_dataset .shownore_link:hover {
        text-decoration: none;
    }
    .datasetContent {
        display: block;
    }
    .datasetContent-Dataset {
        flex-grow: 0;
    }
    .datasetContent-DatasetAssistant {
        max-width: 100%;
    }
    /* end for dataset page */

    /* start for slick slider roundedListItemSlider */
    .slick-slider.roundedListItemSlider {
        padding: 0;
    }
    .slick-slider.roundedListItemSlider .slick-arrow {
        display: none !important;
    }
    .slick-slider.buttonListSlider {
        padding-left: 0;
        padding-right: 0;
    }
    .slick-slider.buttonListSlider .slick-arrow.slick-prev,
    .slick-slider.buttonListSlider .slick-arrow.slick-next {
        display: none !important;
    }
    /* end for slick slider roundedListItemSlider */
}

/*#########################################################################
#########################################################################*/

@media screen and (min-width: 768px) {
    .gridview .gridbox .graph_holder {
        padding: 0;
        height: 30.029vw;
        min-height: auto;
    }
    /*====================================*/
    .largCardView .gridbox {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .listView .gridbox {
        max-width: 100%;
        flex: 0 0 100%;
    }
    /*====================================*/
    .listView .gridbox .graph_holder,
    .listView .gridbox .graph_detail {
        display: inline-block;
        /* float: left; */
    }
    .listView .gridbox .graph_holder img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .dataset_page .listView .gridbox .graph_holder,
    .dataset_page .listView .gridbox .graph_detail {
        float: left;
        margin: 0;
    }
    .listView .gridbox .graph_holder {
        width: 100%;
    }
    .listView .gridbox .graph_detail {
        /* padding-left: 15px; */
        margin-top: 0;
        position: relative;
        height: 100%;
    }

    .listView .gridbox .text-muted {
        position: relative;
        bottom: 0;
        left: 0;
    }
}

/*#########################################################################
#########################################################################*/
@media screen and (min-width: 992px) {
    .gridview .gridbox .graph_holder {
        height: 22vw;
    }
}
/*#########################################################################
#########################################################################*/

@media screen and (min-width: 768px) and (max-width: 991px) {
    .dataset_page .listView .gridbox .graph_detail .relatedDetails {
        width: 100% !important;
        padding: 5px 0 0 0 !important;
    }
}

.MuiInput-underline:before{
    border-bottom: none!important;
}

/* HELP PAGES CSS #########################################################
#########################################################################*/


.help-table td{
    border: none;
}

/* LIBRARY CSS ############################################################
#########################################################################*/

.channel-row-info{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.channel-info{
    margin-top: auto;
    margin-bottom: 0px;
    padding-top: 5px;
}

.library-row-hover:hover{
    border-color: #545454;
    cursor: pointer;
}

.library-navigation {
    font-size: 16px;
    padding-top: 20px;
}

.library-image-holder {
    float: left;
    padding-right: 15px;
}


/* PAGE TITLE CSS #########################################################
#########################################################################*/

.page-title{
    font-size: 36px;
    color: var(--secondary-color);
}
