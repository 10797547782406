.html-metric {
    font: 3em sans-serif;
    word-wrap: break-word;
    color:#333;
    padding:0.25em;
    overflow-x: auto;
}

.first-paragraph {
    font-size: 2em;
    display: block;
}

.other-paragraphs {
    display: inline-block;
    padding-right: 35px;
    padding-left: 35px;
    font-size: 1em;
}

.other-paragraphs:not(:last-of-type) {
    border-right: 2px solid #D3D3D3;
}