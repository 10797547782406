/* Help panel styles */
.help-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    height: calc(100% - 20px)
}

.help-big-screen {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    width: 100%;
    padding: 20px;
    height: 100%;
}

.help-big-screen-row{
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
    padding: 20px;
    height: calc(100% - 20px);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.help-container img {
    height: 150px;
}

.help-container a {
    word-wrap:break-word;
    word-break: break-all;
}

.help-title {
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.help-title img {
    height: 50px;
    padding: 10px;
}

.help-title h4 {
    margin: 0;
}

.close-help-panel{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 0.2rem;
}

.no-display{
    display: none;
}