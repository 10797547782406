.animated {
    opacity: 0;
}

.fadeIn.animated {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out;
}

.fadeInUp.animated {
    opacity: 1;

    -webkit-transform: translate3d(0, 30px, 0);
    -webkit-moz: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
    -webkit-transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;

    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* ======================================================== */
.notification-card-parent {
    position: fixed;
    z-index: 99999;
    bottom: 25px;
    left: 20px;
    right: 20px;
    /* padding: 15px 20px 30px; */
    /* background-color: #fff; */
}

.notification-card {
    background: #fff;
    border: 1px solid #a7a7a7;
    border-radius: 5px;
    color: #191919;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    padding: 10px 0 15px;
}

.notification-card .close-notification {
    right: 8px;
    top: 0px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
}

.notification-card .close-notification img {
    height: 12px;
    width: 12px;
}

.notification-card .notification-logo {
    height: 45px;
    width: 45px;
    object-fit: contain;
}

.notification-card .popper-triangle-bottom {
    height: 15px;
    width: 15px;
    border: solid #a7a7a7;
    position: absolute;
    z-index: 1;
    transform: rotate(45deg);
    border-width: 0 1px 1px 0;
    bottom: -8px;
    background: #fff;
    left: 50%;
}

.notification-card .shareIcon {
    height: 20px;
    width: 25px;
    object-fit: contain;
    margin-top: -4px;
    cursor: pointer;
}
/* ======================================================== */

/* fix padding under Toolbar after resize */
@media screen and (max-width: 767px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (min-width: 992px) {
}
