@import '../variables.css';

.sidebar {
    margin: 0px !important;
    transition: all 0.5s ease-in-out;
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    text-transform: capitalize;
    white-space: nowrap;
}
.sidebar-expanded {
    /* flex: 0 0 80px; */
    /* max-width: 80px; */
    flex: 0 0 var(--sidemenu_width);
    max-width: var(--sidemenu_width);
}
.sidebar-narrow {
    flex: 0 0 0px;
    /* max-width: 80px ; */
    max-width: var(--sidemenu_width);
}
.sidebar-nav-expanded {
    /* width: 80px; */
    width: var(--sidemenu_width);
    left: 0;
}
.sidebar-nav-narrow {
    width: var(--sidemenu_width);
    left: -82px;
}
.sidebar-navbar {
    margin: 0px;
    padding: 0px;
}
.sidebar-nav {
    border-right: 1px solid var(--border_color_grey);
    height: 100vh;
    position: relative;
    transition: width 0.5s ease-in-out;
    background: #455066;
}

.lab {
    background: #fcbd33 !important;
}



/************************************************************/
.sidebar-link,
.expand-collapse {
    padding: 0.5rem;
}
.workspace-name.nav-link,
.sidebar-link.nav-link {
  text-align: center;
}

.footer-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-navbar .workspace-name,
.sidebar-navbar .sidebar-link, 
.sidebar-navbar .expand-collapse,
.footer-navbar .footer-link {
    color: #a7a7a7 !important;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.sidebar-navbar .lab .workspace-name,
.sidebar-navbar .lab .sidebar-link, 
.sidebar-navbar .lab .expand-collapse, 
.footer-navbar .lab .footer-link {
    color: var(--secondary-color) !important;
}

.sidebar-navbar .sidebar-link i, 
.footer-navbar .footer-link i {
    font-size: 25px;
}

.expand-name:hover{
    color: #fff!important;
}

.footer-navbar .footer-link.active,
.footer-navbar .footer-link:hover,
.sidebar-navbar .sidebar-link.active,
.sidebar-navbar .sidebar-link:hover,
.footer-navbar .footer-link.active div i,
.footer-navbar .footer-link:hover div i,
.sidebar-navbar .sidebar-link.active div i,
.sidebar-navbar .sidebar-link:hover div i,
.footer-navbar .footer-link.active div,
.footer-navbar .footer-link:hover div,
.sidebar-navbar .sidebar-link.active span,
.sidebar-navbar .sidebar-link:hover span {
    color: #fbfcfc !important;
    background-color: var(--secondary-color);
}

.footer .linkIcon_holder {
    width: inherit !important;
}

.linkIcon_holder {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
}
.linkIcon_holder .count {
    position: absolute;
    z-index: 9;
    top: -3px;
    right: -25px;
    left: 0;
    margin: auto;
    background: #e50303;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    font-size: 10px;
    color: #fff;
}
/************************************************************/

/* fix padding under Toolbar after resize */
@media screen and (max-width: 767px) {
  .workspace-name {
    padding: 20px 0px 0px 0px !important;
  } 
	.footer {
        position: fixed;
        width: 100%;
        height: 60px;
        border-top: 1px solid #d9d9d9;
        padding: 0;
        left: 0;
        bottom: 0;
        background-color: var(--secondary-color);
        margin: 0;
        text-align: center;
        z-index: 99;
    }
    .footer-navbar,
    .footer-nav {
        background: var(--secondary-color);
        width: 100%;
        padding: 0;
    }
    .footer-link {
        width: calc(100% / 6);
        text-align: center;
        flex-grow: 1;
        flex-basis: 1;
    }
}

@media screen and (min-width: 767px){
    .sidebar-nav-narrow {
        width: var(--sidemenu_width);
        left: 0px;
    }
}

.sidebar-expanded {
    flex: 0 0 160px;
    max-width: 225px;
}
.sidebar-narrow {
    flex: 0 0 0px;
    /* max-width: 80px; */
    max-width: var(--sidemenu_width);
}
.sidebar-nav-expanded {
    width: 225px;
    left: 0;
}
.sidebar-navbar {
    margin: 0px;
    padding: 0px;
}

.sidebar {
    overflow: hidden;
}

.workspace-name.nav-link,
.sidebar-link.nav-link,
.expand-collapse {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 60px;
}

.expandedName {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-arrow {
    transform: scale(0.8);
    border: 1px solid #343f58;
    border-radius: 60%;
}

.sidebar-arrow:hover {
    background-color: #a7a7a7;
}

.workspaces-menu div.dropdown-menu.show{
    position: fixed !important;
    inset: 0px auto auto 0px !important;
    top: 12rem!important;
    left: 4rem!important;
    margin: 0px !important;
    transform: translate(0px, 42px) !important;
}

.workspace_dropdown .workspaces-arrow {
    height: 13px;
    width: 7.7px;
    margin: auto 10px;
    transform: rotate(180deg);
}


.workspace_dropdown .workspaces-arrow-open {
    height: 13px;
    width: 7.7px;
    margin: auto 10px;
    transform: rotate(-90deg);
}

@media screen and (max-width: 767px){
    .workspace_dropdown .workspaces-arrow .dropdown_icon {
        height: 13px;
        width: 7.7px;
        float: right;
        right: 5px;
        top: 15px;
        transform: rotate(-180deg);
    }
    
    .workspaces-menu div.dropdown-menu.show{
        top: 68% !important;
    }

    .workspace_dropdown.show .workspaces-arrow .dropdown_icon {
        transform: rotate(-90deg);
    }
}

.dropdown-toggle::after{
    display: none !important;
}

.profile-menu-medium {
	  width: 100%;
    display: inline-block;
    white-space: nowrap;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.workspace-name:hover{
    background-color: none !important;
}

.expand-collapse-arrow:hover{
    opacity: 0.5;
}

li a.nav-link.dropdown-item:active, 
div a.dropdown-item:active , li div a.dropdown-toggle.nav-link span.dropdown-item.workspaces-arrow.navbar-text:active, li div a.dropdown-toggle.nav-link:active {
    background-color: unset !important;
    outline: none !important;
    color: unset !important;
}

.menu-chain-icon {
    width: 30px !important;
    height: 30px !important;
    color: #3b4458 !important;
    fill: #3b4458 !important;
    margin-right: 10px !important;
    margin-left: -20px !important;
}

.workspace_dropdown .dropdown-menu {
    margin: 5px 0;
    padding: 0 !important;
    /* border-radius: 0; */
    width: 100%;
    border-width: 0 0;
    position: relative;
    height: auto;
    box-shadow: none;
    top: -40px;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item-active {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa !important;
}

.dropdown-item span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa !important;
}

div.workspace_dropdown div.dropdown-menu.show{
    position: relative !important;
    inset: -42px auto auto 0px !important;
}

.nav-link.dropdown-item:active {
	  background-color: unset !important;	
}

.dropdown-item {
   	text-transform: capitalize;
}

.dropdown-subitem {
	  padding-left: 40px;
	  cursor: pointer;
}

a.nav-link.dropdown-item, span.dropdown-item.workspaces-arrow.navbar-text{
    min-height: 43px;
}
a.nav-link.dropdown-item:hover{
    border-radius: 5px;
}

span.dropdown-item.workspaces-arrow.navbar-text:hover{
    border-radius: 5px;
}

.tasks-in-progress-icon{
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    border-radius: 100%;
    background-color: #455066;
    width: 40px;
    height: 40px;
    font-size: 15px;
    color: white !important;
    padding-top: 11px;
    line-height: 16px;
}

.circle-border {
  position: relative;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: linear-gradient(270deg, #009579 50%, transparent 50%), linear-gradient(0deg, #009579, lightgray 50%)
}

.tasks-in-progress-icon:hover{
    color: white;
}
.workspace-url-image {
    width: 30px;
    height: 30px;
    border-radius: 10%;
}
.workspace-menu-header {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px 16px 10px 16px;
}
.workspace-menu-name {
  color: #454F66;
  font-size: 16px;
  font-weight: 500;
  width: 90vw;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: capitalize;
}
.workspace-menu-name .linkIcon_holder {
	width: 60px;
	display: flex;
	justify-content: center;
}
.workspaces-menu-header {
	color: #454F66 !important;
	font-size: 19px;
	line-height: 23px;
	font-weight: 500;
	padding: 10px 16px 10px 16px;
}