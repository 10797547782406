/* content when no starred datasets */
.no-starred {
    min-height: 90vh;
}

.no-starred h1 {
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
}

.no-starred i {
    font-size: 15em;
    color: #a7a7a7;
    padding: 0px 50px 50px 50px;
}
