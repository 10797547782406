@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import '../variables.css';

.brand {
    padding: 0px;
    margin: 0 13px 0 18px;
}

.brand img {
    height: 30px;
    margin: 0;
}

.brand span {
    font-size: 20px;
    left: 105px;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.navigate-brand {
    cursor: pointer;
}

.toolbar {
    z-index: 100 !important;
    border-bottom: 1px solid var(--border_color_grey);
    height: var(--toolbar_height);
    padding: 0;
    text-transform: capitalize;
}

.toolbar i {
    /* font-size: 25px; */
    /* center vertically */
    margin: 0px 5px 0px 0px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.toolbar a {
    color: rgba(0, 0, 0, 0.5);
}

.toolbar form {
    width: 100%;
    padding: 0;
}
.toolbar .input-group {
	box-shadow: none;
    color: #b1b1b1;
    transition: box-shadow 300ms ease-out;
}
.toolbar .toolbar-search-input:focus {
    box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%), 0 1px 3px 1px rgb(65 69 73 / 15%);
    border-radius: 8px;
}
.toolbar .toolbar-search-input {
    border: none;
    background: transparent;
	font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    height: auto;
    padding-left: 45px;
}
.toolbar .toolbar-search-input input::placeholder {
    color: #b1b1b1;
    font-weight: 400;
    opacity: 1; /* Firefox */
}

.toolbar input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b1b1b1;
    font-weight: 400;
}

.toolbar input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b1b1b1;
    font-weight: 400;
}

.toolbar .toolbar-search {
    border-radius: 8px;
    background: #f1f3f4;
    margin: auto 140px auto 0;
    display: flex;
    justify-content: flex-start;
}

/* suggestionsContainer */
.toolbar-search > div:first-of-type {
    flex: 1;
}

/* tagsContainer */
.toolbar-search > div:nth-of-type(2) {
    width: 0%;
}

.toolbar .toolbar-search-icon {
  min-width: 40px;
}

.toolbar .toolbar-search-icon img {
    width: 14px;
    object-fit: contain;
}

.toolbar .suggestions input {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.toolbar .toolbar-search-suggestionlist {
	max-height: none;
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%), 0 1px 3px 1px rgb(65 69 73 / 15%);
}

@media screen and (max-width: 767px){
  .toolbar .toolbar-search {
    margin: auto 150px auto 0 !important;
  }
  .toolbar .toolbar-search-input {
    padding-left: 25px;
  }
}

.hamburgermenu_holder {
    width: var(--sidemenu_width);
    text-align: center;
}
.hamburger_menu {
    height: 30px;
    width: 34px;
    border: 1px solid #c5c5c5;
    padding: 5px 6px;
    border-radius: 4px;
    cursor: pointer;
    /* margin-right: 15px; */
    display: inline-block;
    margin-top: 4px;
}
.hamburger_menu span {
    width: 20px;
    height: 2px;
    margin: 3px 0;
    background: #c5c5c5;
    display: block;
    text-align: center;
}

.toolbar-close {
    left: 30px;
}

.toolbar-search {
    right: 55px;
}
.toolbar-user {
    right: 20px;
    color: #a7a7a7;
    padding: 0 0 0 15px;
    align-items: center;
}
.toolbar-user img {
    width: 24px;
    height: 19px;
}

.toolbar-user .btn-link {
    padding: 0 12px 0 0;
}
.user_image img {
    height: 34px;
    width: 34px;
    object-fit: cover;
    border-radius: 50%;
}

.toolbar-notification {
    position: relative;
    display: inline-flex;
    min-width: 31px;
}
.toolbar-notification svg {
    height: 19px;
    width: auto;
}
.toolbar-notification:hover {
    text-decoration: none;
}
.toolbar-notification .redcount {
    background: #e50303;
    color: #fff;
    border-radius: 10px;
    min-width: 13px;
    height: 13px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 13px;
    font-size: 8px;
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: -2px;
    left: -6px;
    margin-right: 6px;
}

.toolbar .dropdown-toggle::after {
    display: none;
}
.dropdown-toggle.nav-link {
    padding: 0;
}
.profile_dropdown {
    display: inline-flex;
    align-items: center;
    padding: 10px;
}
.toolbar-profile-items {
	 position: absolute;
	 right: 0;
	 top: 0;
	 display: inline-flex;
	 height: var(--toolbar_height);
}

/* fix padding around search after resize */
@media screen and (max-width: 767px) {
    .toolbar-close {
        left: 15px;
    }

    /* -------------- */
    .navbar.toolbar {
        flex-wrap: inherit;
    }
    .toolbar {
        padding: 10px 0;
    }
    .brand {
        width: auto;
        padding: 0 15px;
        margin: 0;
    }
    .brand img {
        height: 38px;
        width: 38px;
    }
    /* .collapse:not(.show) {
    display: flex;
    flex-basis: auto;
    max-width: calc(100% - 240px);
  } */

    .toolbar .toolbar-search-icon {
      display: none;
    }
    .toolbar input {
      padding-left: 20px;
    }
    .toolbar-notification svg {
        height: 22px;
        width: auto;
    }
    .toolbar-user img {
        width: 27px;
        height: 22px;
    }
    .user_image img {
        height: 35px;
        width: 35px;
    }
    .toolbar-notification .redcount {
        top: -1px;
        left: -7px;
    }
    /* -------------- */

    .profile_dropdown .profile_dropdown_head {
        padding: 0px 25px 10px;
    }
    .profile_dropdown .close_profiledropdown {
        padding-bottom: 0;
    }
    .profile_dropdown .close_profiledropdown img {
        width: 14px;
        position: relative;
        right: -8px;
    }
    .profile_dropdown .dropdown-menu {
        width: 100vw;
        top: -12px;
    }

    .profile_dropdown .dropdown-menu.dropdown-menu-right {
        right: -100vw;
    }
    /* hide header */
    .noHeader {
        display: none;
    }
}

.toolbar-invite {
    margin-left: 0 !important;
}

.toolbar-name-mobile{
    margin-left: 4rem;
    justify-content: flex-start;
}

.toolbar-invite {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.toolbar-name h2 span {
    color: #454F66 !important;
}

.toolbar-name-mobile h2 span {
    color: #454F66 !important;
}

@media screen and (max-width: 767px){
    .toolbar-name{
        justify-content: flex-start;
        transform: scale(0.9);
        min-width: 42%;
        margin-left: -1rem;
    }
    .toolbar-name-mobile{
        justify-content: flex-start;
        transform: scale(0.9);
        min-width: 42%;
        margin-left: -1rem !important;
    }
}

@media screen and (max-width: 540px){
    .workspace-name-mobile{
        display: none !important;
    }
}