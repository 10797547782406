.data-job-row {
    position: relative;
    justify-content: space-between;
    padding: 10px 20px;
    text-wrap: nowrap;
}

.data-job-row::after {
    position: absolute;
    height: 1px;
    content: '';
    background: #d7dbe2;
    bottom: 0;
    left: 50%;
    width: 95%;
    margin-left: -47.5%;
}

.data-job-row:hover {
    -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    -moz-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.data-job-row:hover::after,
.data-job-row:nth-last-child(2)::after {
    content: none;
}

.data-job-row .info {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.data-job-row .info > * {
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #f1f2f5;
    padding: 0 10px;
    border-radius: 5px;
    font-family: 'Nunito Sans', sans-serif;
}

.data-job-row .info img {
    width: 10px;
    height: 10px;
}
