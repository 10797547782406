@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

:root {
    --primary-color: #191919;
    --secondary-color: #2a303e;
    --sidemenu_width: 80px;
    --toolbar_height: 54px;
    --backbar_height: 45px;
    --border_color_grey: #dbdbdb;
    --help-big-screen: calc(100% - 20px);
    --image-holder: 120px;
    --image-holder-small: 80px;
}

@media screen and (max-width: 767px) {
  :root {
      --toolbar_height: 60px;
  }
}
/* primaryColor="#d9d9d9"
  secondaryColor="#ecebeb" */

.dataLoader_parent .boxload rect {
    background-color: #d9d9d9;
}

.dataLoader_parent .circleSliderPlaceholder {
    width: 100%;
}

.dataLoader_parent .circleSliderPlaceholder circle {
    fill: #d9d9d9;
}

/* .dataLoader_parent .circleSliderPlaceholder rect {
  fill: #ecebeb;
} */

.dataLoader_parent .placeholderDivider rect {
    width: 100%;
    fill: #d9d9d9;
}

.dataLoader_parent .placeholderList {
    width: 100%;
}

.dataLoader_parent .hideOverflow {
    overflow: hidden;
}

@font-face {
    font-family: 'roboto';
    src: url(/static/media/Roboto-Thin.c38fab23.ttf);
    src: url(/static/media/Roboto-Thin.0fd95957.eot);
    src: url(/static/media/Roboto-Thin.a8227d7e.woff);
    font-weight: 100;
}

@font-face {
    font-family: 'roboto';
    src: url(/static/media/Roboto-Light.0cea3982.ttf);
    src: url(/static/media/Roboto-Light.e6dfb5cc.eot);
    src: url(/static/media/Roboto-Light.e877c961.woff);
    font-weight: 300;
}
@font-face {
    font-family: 'roboto';
    src: url(/static/media/Roboto-Regular.03523cf5.ttf);
    src: url(/static/media/Roboto-Regular.a0a70ade.eot);
    src: url(/static/media/Roboto-Regular.3c8fba37.woff);
    font-weight: 400;
}
@font-face {
    font-family: 'roboto';
    src: url(/static/media/Roboto-Medium.13a29228.ttf);
    src: url(/static/media/Roboto-Medium.07fefb8c.eot);
    src: url(/static/media/Roboto-Medium.bdf64184.woff);
    font-weight: 500;
}
@font-face {
    font-family: 'roboto';
    src: url(/static/media/Roboto-Bold.4f39c579.ttf);
    src: url(/static/media/Roboto-Bold.056ecee6.eot);
    src: url(/static/media/Roboto-Bold.aef07b72.woff);
    font-weight: 700;
}
@font-face {
    font-family: 'roboto';
    src: url(/static/media/Roboto-Black.1224b64c.ttf);
    src: url(/static/media/Roboto-Black.f1d6a1a7.eot);
    src: url(/static/media/Roboto-Black.b9cb7264.woff);
    font-weight: 900;
}

body {
    font-family: 'roboto';
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: var(--primary-color);
    background-color: #ffffff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--primary-color);
}
h1 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 10px;
}
h2 {
    font-size: 17px;
    line-height: 23px;
    font-weight: 400;
    margin-bottom: 8px;
}
h3 {
    font-size: 15px;
}
h4 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}
h6 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}


/* Center div content vertically and horizontally */
.content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-control {
    color: var(--primary-color);
}
.form-control:focus {
    box-shadow: none;
}
.disable_resize {
    resize: none;
}

/*=============================================*/

.rightside_pageContent {
    position: relative;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.full_pageContent,
.rightside_pageContent > :not(hr) {
    margin: 15px;
}

/* Fixes workspace edit screen in the lab - otherwise very narrow */
.rightside_pageContent > .data-page {
    max-width: 100%;
}

.toggleTopOnScroll .workbench.show_sidebar .stickyTop,
.workbench.show_sidebar .rightside_pageContent,
.toolbar.show_sidebar .rightside_pageContent {
    padding-left: var(--sidemenu_width);
}

.toggleTopOnScroll .workbench.hide_sidebar .stickyTop,
.workbench.hide_sidebar .rightside_pageContent,
.toolbar .rightside_pageContent {
    padding-left: 80px;
}
.toggleTopOnScroll .workbench.show_sidebar .stickyTop,
.workbench.show_sidebar .rightside_pageContent,
.toolbar.show_sidebar .rightside_pageContent {
    padding-left: 225px;
}

@media screen and (max-width: 767px) {
    .toggleTopOnScroll .workbench.hide_sidebar .stickyTop,
    .workbench.hide_sidebar .rightside_pageContent,
    .toolbar .rightside_pageContent {
        padding-left: 0;
    }
    .custom_container {
        padding: 0px 0px;
    }
    .backbar {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.custom_container {
    flex: 1 1 1px; /* https://stackoverflow.com/a/52489012 */
}

.pad_tb10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.pad_tb14 {
    padding-top: 14px;
    padding-bottom: 14px;
}
.pad_tb15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pad_lr10 {
    padding-right: 10px;
    padding-left: 10px;
}
.pad_lr15 {
    padding-right: 15px;
    padding-left: 15px;
}
.pad_l15 {
    padding-left: 15px;
}
.pad_l30 {
    padding-left: 30px;
}

.pad_0 {
    padding: 0;
}
.pad_5 {
    padding: 5px;
}
.pad_10 {
    padding: 10px;
}
.pad_15 {
    padding: 15px;
}
.pad_top0 {
    padding-top: 0px;
}
.pad_top10 {
    padding-top: 10px;
}
.pad_top15 {
    padding-top: 15px;
}
.pad_bot10 {
    padding-bottom: 10px;
}
.pad_bot15 {
    padding-bottom: 15px;
}
.pad_bot25 {
    padding-bottom: 25px;
}

.pad_r0 {
    padding-right: 0;
}
.pad_r20 {
    padding-right: 20px;
}
.pad_r30 {
    padding-right: 30px;
}
/*--------------------------------------------*/
.m_0 {
    margin: 0;
}
.m_top4 {
    margin-top: 4px;
}
.m_top10 {
    margin-top: 10px;
}
.m_top20 {
    margin-top: 20px;
}
.m_bot0 {
    margin-bottom: 0;
}
.m_bot10 {
    margin-bottom: 10px;
}
.m_bot20 {
    margin-bottom: 20px;
}
.m_left15{
    margin-left: 15px;
}
.m_right10{
    margin-right: 10px
}
/*--------------------------------------------*/
.min_width300{
    min-width: 300px;
}
/*=============================================*/
.fullScreenSize {
    width: 100%;
    height: 100vh;
    background: #fff;
}

.mw-auto {
    min-width: auto !important;
}
.RetryErrorBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.RetryErrorBox img {
    width: 82px;
    /* height: 55px; */
    object-fit: contain;
}
.RetryErrorBox h1 {
    margin: 25px 0 0;
    font-size: 30px;
    line-height: 22px;
    font-weight: 500;
    color: #ff9e00;
}
.RetryErrorBox h2 {
    margin: 8px 0 0;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: var(--primary-color);
}
.RetryErrorBox button {
    margin-top: 26px;
    background: #f29048;
    border: 1px solid #f29048;
    border-radius: 4px;
    padding: 6px 10px 5px;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    width: 115px;
}

/*=============================================*/
.loader_parent {
    width: 100%;
    height: 100vh;
    /* position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    background: #fff;
}
.loaderfadeOut {
    transition: opacity 0.8s ease-out;
    opacity: 0;
}

.loaderfadeOut .loader_parent {
    transition: visibility 0.8s ease-out;
    visibility: hidden;
}
.loader {
    position: absolute;
    height: 100px;
    width: 100px;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dataLoader_parent {
    height: 100%;
    background-color: #fff;
}

.loaderfadeOut .dataLoader_parent {
    transition: visibility 0.8s ease-out;
    visibility: hidden;
}
.dataLoader {
    width: 100%;
}

/* ------------------------------------ */
.text-muted {
    color: #989898 !important;
}

/*=============================================*/

/*===============================================
Start Common Form Style   =================*/

/* --------------------------------- */
.customSelectbox select {
    width: auto;
    padding: 0 30px 0 10px;
    height: auto;
    color: #a7a7a7;
    font-size: 13px;
    line-height: 17px;
    border: none;
    float: right;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
}
.customSelectbox select:focus {
    border: none;
    outline: none;
    background: transparent;
}
.customSelectbox:after {
    content: '\25BE';
    color: #a7a7a7;
    display: inline-block;
    position: absolute;
    z-index: 11;
    right: 0px;
    top: 0px;
    pointer-events: none;
    font-size: 16px;
    transform: scaleX(1.6);
}
/* --------------------------------- */
.spinner_btnHolder {
    min-width: 130px;
    text-align: center;
}
.spinner-border {
    border: 3px solid #f29048;
    border-right-color: transparent;
    height: 35px;
    width: 35px;
}
.validation_msg {
    color: red;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 15px;
}
.form-group {
    margin-bottom: 15px;
}
.form-group .form-control {
    border: 1px solid #707070;
    margin-top: 6px;
}
.form-group .form-control:focus {
    box-shadow: none;
    border-color: #707070;
}
.form-group label {
    font-size: 15px;
    line-height: 22px;
    color: var(--primary-color);
    margin-bottom: 0;
}
.text-muted {
    color: #989898 !important;
    margin-top: 2px;
}
.form-text {
    font-size: 12px;
    line-height: 15px;
    margin-top: 5px;
}
.f-16-l-20 {
    font-size: 16px;
    line-height: 18px;
}

.btn-warning {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    background: #f29048;
    border: 1px solid #f29048;
    color: #fff;
    padding: 7px 30px 4px;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    background: #f29048;
    border-color: #f29048;
    color: #fff;
}

.btn-danger {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
    padding: 7px 30px 4px;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

/*  End Common Form Style   =====================
===============================================*/
/*===============================================
  Start Modal Style   =========================*/
.modal .modal-title.h4 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
}
.modal-body {
    font-size: 15px;
    line-height: 22px;
}
.modal-footer .btn {
    font-size: 14px;
    line-height: 22px;
    padding: 0.375rem 0.75rem;
}
.modal-footer .btn-secondary:not(:disabled):not(.disabled).active:focus,
.modal-footer .btn-secondary:not(:disabled):not(.disabled):active:focus,
.modal-footer .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}
/*  End Modal Style   ===========================
===============================================*/

/*===============================================
  Start new insight popup Style   =============*/
.newInsightBox {
    background: #fff;
    padding: 12px 10px;
    border-radius: 20px;
    position: fixed;
    z-index: 9;
    top: 50px;
    left: 0;
    right: 0;
    width: 140px;
    margin-left: auto !important;
    margin-right: auto !important;
    box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.15);
}
/*  End new insight popup Style   ===============
===============================================*/

.topbar > form > .input-group {
    border: 1px solid #c5c5c5;
    border-radius: 4px;
}
.topbar > form > .input-group input.form-control {
    border-width: 0;
}
.topbar > form > .input-group button.input-group-append {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0;
}

.topbar > form > .input-group button.input-group-append > img {
    width: 14px;
}
.backbar {
    font-size: 13px;
    line-height: 16px;
    color: #a7a7a7;
    margin: 15px 0;
    /* padding-left: 18px; */
    padding-right: 15px;
}
.backbar img {
    width: 10px;
    height: 15px;
    margin-right: 3px;
}
.backbar span {
    margin: auto 0;
}
.breadcrumb {
    padding: 0;
    margin: 0;
    background: transparent;
}
.breadcrumb span {
    color: #a7a7a7;
}
.breadcrumb:hover {
    text-decoration: none;
}
.cursor-pointer {
    cursor: pointer;
}
.autoHeight {
    height: auto;
}
.width100 {
    width: 100px;
}
.dataset-card .rightSide_verticalDots {
    top: 0;
}
.rightSide_verticalDots {
    position: absolute;
    right: 0;
    z-index: 2;
}

/*==============================================*/
.custom_alertbox .alert {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);

    border: 1px solid #dedede;
    font-size: 15px;
    line-height: 19px;
    margin: 0;
}
.alert-warning {
    border: 1px solid #f29048;
    background: #fff7f2;
    color: #f29048;
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    padding: 10px;
}

.input_with_copy_icon {
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px;
    margin-top: 11px;
    cursor: pointer;
}
.input_with_copy_icon .form-control {
    border: none;
    padding: 0 10px 0 0;
    margin: 0;
    height: auto;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
}
.input_with_copy_icon .input-group-append img {
    height: 20px;
    width: 17px;
    object-fit: contain;
}
/*================================================
Start Graph Pages ==============================*/
.selectViewButton .view_icon {
    background: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
    padding: 5px 10px;
}
.selectViewButton .view_icon svg {
    height: 19px;
    fill: #a7a7a7;
}
.selectViewButton .view_icon.active svg {
    fill: #7c7c7c;
}
.fullWidth.largCardView.custom_container,
.fullWidth.listView.custom_container {
    padding-top: 5px;
}
.fullWidth.largCardView .dataset-card {
    min-height: 50vh;
}

.news-card {
    width: 90%;
    margin: 0 auto;
}
.dataset-card {
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;
}
.subtitle {
    min-height: 28px;
    font-size: 13px;
    line-height: 17px;
    color: #646464;
}
.gridbox {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0;
}
.gridbox:hover{
    border-color: #545454;
}
.gridbox h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}

.dataset-card-details {
    padding: 10px;
    width: 100%;
    border-top-color: #ddd;
    border-top-style: solid;
    border-top-width: thin;
}

.listView .dataset-card-details {
    border-top: none;
}

.fullWidth.gridview {
    grid-gap: 12px;
    gap: 12px;
}

.gridview .gridbox {
    width: calc(33% - 6px);
}

.gridbox .detailslink:hover {
    text-decoration: none;
}
.gridbox .detailslink:hover h2 {
    color: var(--primary-color);
}
.gridbox .detailslink:hover .text-muted p {
    color: #a5a5a5 !important;
}
.graph_holder {
    background: #ffffff;
    overflow: hidden;
    border-radius: inherit;
    width: 100%;
    margin: 15px auto
}

.dataset-card .graph_holder {
    margin: 0;
}

.gridview .gridbox .graph_holder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.graph_detail {
    width: 100%;
    margin-top: 0px;
    min-height: 42px;
    padding-left: 35px;
    background: url(/static/media/logo.fc4fbc77.svg) no-repeat;
    background-size: 28px 28px;
}
.gridview .graph_detail h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    width: 95%;
    margin-bottom: 0px;
}
.gridbox .text-muted {
    color: #a7a7a7 !important;
}
.gridbox .text-muted p {
    /* display: inline-block; */
    float: left;
    margin-bottom: 0;
    margin-top: 0;
}
.gridbox .text-muted p:not(:last-child) {
    width: 100%;
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* common in view */
.fullWidth.largCardView .gridbox .graph_detail h2,
.fullWidth.listView .gridbox .graph_detail h2 {
    top: 0px;
    position: relative;
    margin-bottom: 0px;
}
.fullWidth.listView .gridbox .graph_detail p {
    top: 0px;
    margin-bottom: 5px;
}
/* common in view */

/* start graph list view */
.listView .gridbox .detailslink {
    display: flex;
}
.listView .gridbox .detailslink .graph_holder {
    height: auto;
    max-height: 250px;
}
.fullWidth.listView .gridbox .detailslink {
    display: block;
    position: relative;
}

.fullWidth.listView .gridbox .detailslink:hover:before {
    bottom: 15px;
}
@media screen and (max-width: 767px) {
    /* common in list and largeCard  view */
    .fullWidth.largCardView .gridbox .graph_detail,
    .fullWidth.listView .gridbox .graph_detail {
        width: 100%;
        background-position: 0 0;
        padding-left: 55px;
        margin-top: 0;
    }
    /* common in list and largte view */

    .selectViewButton .view_icon svg {
        height: 15px;
    }
    .fullWidth.listView .gridbox .graph_holder {
        display: none;
    }
    .fullWidth.listView.custom_container {
        padding-top: 10px;
    }
    .gridbox {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .fullWidth.listView .gridbox {
        max-height: 250px;
    }
    .fullWidth.listView .gridbox .graph_detail h2 {
        min-height: 44px;
    }
}
/* end graph list view */
.fullWidth.largCardView .graph_holder {
    height: 100%;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 20px;
}
/*==================================
 Start News Page =================*/
.fullWidth.largCardView.singleView.custom_container {
    padding-top: 28px;
}
.largCardView.singleView .gridbox {
    padding: 0;
}
.largCardView.singleView .usercomment {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #646464;
}
.largCardView.singleView .usercomment b {
    font-weight: 500;
}
.largCardView.singleView .usercomment .text-muted {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #a5a5a5;
}
.largCardView.singleView .usercomment.toggleHeight {
    /* max-height: 50px; */
    overflow: hidden;
    position: relative;

    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
}
.largCardView.singleView .usercomment.toggleHeight .readmore {
    text-decoration: underline;
    background: #fff;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    padding: 0 10px;
}
.largCardView.singleView .graph_holder {
    padding: 10px !important;
    margin-top: 0;
}
.largCardView.singleView .borderedSection {
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 15px;
    overflow: hidden;
}
.largCardView.singleView .divider {
    margin: 27px 0 23px;
    height: 1px;
    display: block;
    background: #cecece;
}
.largCardView.singleView .gridbox:last-child .divider {
    background: transparent;
    margin-bottom: 0;
}

/* End News Page ================
==================================*/

.loadMoreDatasetButton {
    text-align: center;
    padding: 30px;
}
.loadMoreDatasetButton.spinner-border {
    animation: spinner 1s linear infinite;
}
.loadMoreDatasetButton button.spinner-border {
    background-color: transparent;
    border: 3px solid #f29048;
    border-top-color: transparent;
    height: 35px;
    width: 35px;
    padding: 0;
    display: inline-block;
    border-radius: 30px;
}
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 767px) {
    .graph_detail {
        margin-top: 15px;
    }
    /*==================================
     Start News Page =================*/
    .fullWidth.largCardView.singleView.custom_container {
        padding-top: 22px;
    }
    /* .largCardView.singleView .gridbox {
        padding: 0;
    } */

    .largCardView.singleView .detailslink {
        /* padding-left: 0; */
        padding: 15px;
    }
    .largCardView.singleView .graph_holder {
        padding: 5px !important;
        /* margin-top:0; */
    }
    .largCardView.singleView .divider {
        margin: 17px 0 20px;
        height: 3px;
    }

    /* End News Page ================
    ==================================*/
}

@media screen and (min-width: 767px) {
    /*==================================
     Start News Page =================*/
    .fullWidth.largCardView.singleView.custom_container {
        padding-top: 30px;
        justify-content: center;
    }
    .fullWidth.largCardView.singleView .gridbox {
        flex: unset;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
        display: block;
    }
    .largCardView.singleView .graph_detail {
        min-height: 54px;
        padding-left: 70px;
        background-size: 54px 54px;
    }
    .largCardView.singleView .usercomment {
        font-size: 22px;
        line-height: 34px;
    }

    .largCardView.singleView .usercomment .text-muted {
        font-size: 18px;
        line-height: 34px;
    }
    .largCardView.singleView .usercomment.toggleHeight {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        /* max-height: 66px; */
    }
    .largCardView.singleView .graph_holder {
        padding: 17px !important;
    }
    .largCardView.singleView .borderedSection .graph_detail {
        padding-left: 77px;
    }
    .largCardView.singleView .divider {
        margin: 72px 0 30px;
    }
    /* End News Page ================
    ==================================*/
}

/*  End Graph Pages ============================
================================================*/

/* ================================================
    Start Slider Style ==========================*/
.roundedListItemSlider_holder.SliderWith_rightSide_verticalDots {
    padding: 0 34px 0 15px;
}
.roundedListItemSlider_holder .rightSide_verticalDots {
    right: 15px;
}
.roundedListItemSlider_holder .rightSide_verticalDots {
    top: 16px;
}
.roundedListItemSlider_holder .roundedSliderItem {
    list-style: none;
    float: left;
    text-align: center;
}
.roundedListItemSlider_holder .roundedSliderItem .item_icon {
    max-height: 42px;
    min-height: 42px;
    display: unset;
}
.roundedListItemSlider_holder .roundedSliderItem .channel_name {
    padding: 5px 0 0;
    margin: 0;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.roundedListItemSlider_holder .roundedSliderItem.disableChannel .item_icon {
    filter: grayscale(100%);
    opacity: 0.4;
}
.roundedListItemSlider_holder .roundedSliderItem.disableChannel:hover .item_icon {
    filter: grayscale(0%);
    opacity: 1;
}

/* markdown editor on dataset */

.dataset-editor {
    padding-bottom: 30px;
}

.wmde-markdown h1 {
    border-bottom: none !important;
    padding-bottom: 0.3em;
}

.wmde-markdown h2 {
    border-bottom: none !important;
    padding-bottom: 0.3em;
}

.desceditor .w-md-editor-text-pre code[class*="language-"], pre[class*="language-"] {
    margin: 0 !important;
    border: 0 !important;
    background: none !important;
    box-sizing: inherit !important;
    display: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: inherit !important;
    -webkit-font-variant-ligatures: inherit !important;
    font-feature-settings: inherit !important;
    font-variant-ligatures: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
    tab-size: inherit !important;
    text-indent: inherit !important;
    text-rendering: inherit !important;
    text-transform: inherit !important;
    white-space: pre-wrap !important;
    overflow-wrap: inherit !important;
    word-break: inherit !important;
    word-break: normal !important;
    padding: 0 !important;
    line-height: 18px !important;
}

.desceditor .w-md-editor-text-input {
	  line-height: 18px !important;
}

.editorActionButtons {
    padding-top: 10px;
}
.editorActionButtons button.btn-warning {
    padding: 6px 15px 5px;
}
.editorActionButtons button.btn-secondary {
    background: transparent;
    padding: 0;
    border: none;
    height: 35px;
}
.editorActionButtons .btn-secondary:not(:disabled):not(.disabled).active,
.editorActionButtons .btn-secondary:not(:disabled):not(.disabled):active,
.editorActionButtons .show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}
.editorActionButtons button.btn-secondary img {
    height: 100%;
    padding: 8px 10px;
}
/* ----------- share popup slider ----------- */
.slider_shareIcons .slick-track {
    display: flex;
    min-width: 100%;
}
.slider_shareIcons {
    padding: 0 25px;
}
.slider_shareIcons .slick-arrow {
    height: 100%;
    background: #fff;
    width: 25px;
    z-index: 1;
}
.slider_shareIcons .slick-arrow:before {
    top: auto !important;
    font-size: 20px !important;
    -webkit-text-stroke: 0 !important;
    transform: scaleX(2) !important;
    margin-top: -20px;
    color: #5e5e5e !important;
}
.slider_shareIcons .slick-arrow.slick-next:before {
    right: -8px !important;
}
.slider_shareIcons .slick-arrow.slick-prev:before {
    left: -8px !important;
}
.slider_shareIcons .item {
    padding: 0 12px 10px;
    text-align: center;
}
.slider_shareIcons .item .btn-link {
    padding: 0;
    border: none;
    font-size: 14px;
    line-height: 19px;
    color: #373737;
}
.slider_shareIcons .item .btn-link {
    text-decoration: none;
}
.slider_shareIcons .item img {
    object-fit: contain;
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 10px;
    display: inline-block;
}
/* --------------------- */
.sharePopup {
    margin: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.sharePopup .modal-body {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.sharePopup.show .modal-body {
    opacity: 1;
}
.sharePopup .modal-header {
    padding: 25px 30px 0;
    border-bottom: none;
}
.sharePopup .modal-header .modal-title.h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #373737;
}
.sharePopup .modal-header .close span {
    display: none !important;
}
.sharePopup .modal-header .close {
    position: relative;
}
.sharePopup .modal-header .close:before,
.sharePopup .modal-header .close:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 5px;
    height: 20px;
    width: 20px;
    border: solid #000;
}
.sharePopup .modal-header .close:before {
    border-width: 0 2px 0 0;
    transform: rotate(45deg);
    right: 15px;
}
.sharePopup .modal-header .close:after {
    border-width: 0 0 0 2px;
    transform: rotate(-45deg);
    right: 2px;
}
.modal-backdrop {
    z-index: 9999;
}
.modal {
    z-index: 99999;
}
.sharePopup .modal-body {
    padding: 18px;
}
.sharePopup .modal-content {
    border: none;
    border-radius: 0;
}
.sharePopup.watchPopup .modal-dialog {
    min-width: 300px;
}
.sharePopup.watchPopup .modal-header {
    padding: 15px 20px 0;
    border-bottom: none;
}
.sharePopup.watchPopup .modal-body {
    padding: 15px 20px;
}
.sharePopup.watchPopup .modal-body .message {
    padding: 0px 0px 15px 0px;
}
.sharePopup.watchPopup .slider_shareIcons {
    padding: 0;
}
.sharePopup.watchPopup .slider_shareIcons .item {
    padding: 0 6px 10px;
}
@media screen and (min-width: 576px) {
    .modal-dialog {
        min-width: 500px;
    }
}
/* --------------------- */
.tosterMessage {
    position: fixed;
    z-index: 99999;
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #febc41;
    color: #fff;
    border: none;
    border-radius: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0 !important;
}
@media screen and (max-width: 767px) {
    .sharePopup .modal-dialog {
        max-width: 100%;
        width: 100%;
        margin: 0;
    }
    .sharePopup {
        align-items: flex-end;
        z-index: 9999;
    }
    .slider_shareIcons {
        padding: 0;
    }
    .slider_shareIcons .slick-arrow {
        display: none !important;
    }
}

/* ----------- channel list slider ----------- */
.roundedListItemSlider {
    padding: 0 57px;
    overflow: hidden;
}
.slick-slider.roundedListItemSlider .slick-arrow {
    background: #a7a7a7;
    color: #fff;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    top: 0px;
    transform: translate(0px, 0px);
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.slick-slider.roundedListItemSlider .slick-arrow:before {
    top: 9px;
    margin: auto;
    color: #fff;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.slick-slider.roundedListItemSlider .slick-arrow.slick-disabled,
.slick-slider.roundedListItemSlider .slick-arrow.slick-disabled:before {
    opacity: 0.3;
    cursor: default;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.slick-slider.roundedListItemSlider .slick-arrow.slick-prev:before {
    left: 0;
}
.slick-slider.roundedListItemSlider .slick-arrow.slick-next:before {
    right: 0;
}
/* -------------------------------------------- */
/* ------------- button list ------------- */
.slick-slider .slick-arrow:before {
    color: #a7a7a7;
    opacity: 1;
    position: absolute;
    z-index: 1;
    top: -2px;
    font-size: 20px;
    -webkit-text-stroke: 1px;
    transform: scaleX(1.8);
}
.slick-slider .slick-arrow.slick-prev:before {
    content: '\3008';
    left: -3px;
}
.slick-slider .slick-arrow.slick-next:before {
    content: '\3009';
    right: -3px;
}
.slick-slider .slick-arrow.slick-prev {
    left: 0;
}
.slick-slider .slick-arrow.slick-next {
    right: 0;
}

.slick-slider .slick-arrow.slick-disabled:before {
    color: #dedede;
}

.slick-arrow:before {
    opacity: 1 !important;
}
/* ------------------------------------------- */
.buttonListSlider {
    padding: 10px 35px;
    overflow: hidden;
}
.slick-slider.buttonListSlider .slick-slide {
    padding: 0 10px 0 0;
}
.slick-slider.buttonListSlider .slick-track {
    display: flex;
    margin-left: 0;
}
.buttonListItem {
    background: transparent;
    border: 1px solid #a7a7a7;
    color: #cbcbcb;
    border-radius: 4px;
    font-size: 17px;
    line-height: 21px;
    font-weight: 400;
    padding: 8px 15px 8px;

    white-space: nowrap;
}
.buttonListItem.active {
    background: #fdbb41;
    border-color: #fdbb41;
    color: #fff;
}

/*  End Slider Style ============================
================================================*/

/*===============================================
    Start channel list Style ==================*/
.channel_row {
    padding: 7px 10px;
}
.channel_row .channel_icon,
.channel_row .channel_details,
.channel_row .channel_reminder {
    float: left;
}

.channel_row .channel_icon {
    width: 42px;
}
.channel_row .channel_details {
    width: calc(100% - 66px);
    padding: 0 10px;

    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
}
.channel_row .channel_reminder {
    width: 24px;
    color: #a7a7a7;
    font-size: 18px;
    text-align: center;
}
.channel_row .channel_icon img {
    width: 42px;
    height: 42px;
    object-fit: contain;
}
.channel_row .channel_details .description {
    font-size: 14px;
    line-height: 19px;
    color: #9a9a9a;
}
/*  End channel list Style =======================
================================================*/

/*===============================================
    Start library page Style ==================*/
.library_desc {
    font-size: 13px;
    line-height: 17px;
}
/*  End library page Style =======================
================================================*/

/*===============================================
    Start inbox page Style ====================*/

.inbox_page .channel_list {
    /* padding: 0 10px 0 20px; */
    padding: 0 5px;
}
.inbox_page .channel_row {
    /* padding: 14px 0;
    border-bottom: 1px solid #DEDEDE; */
    position: relative;
    padding: 0;
}
.inbox_page .channel_row .channel_icon,
.inbox_page .channel_row .channel_details {
    cursor: pointer;
}
.inbox_page .channel_row.read .channel_details {
    font-weight: 400;
}
.inbox_page .channel_row.unread .channel_details {
    font-weight: 500;
}
.inbox_page .channel_row .pad_tb14,
.inbox_page .swipeable-list-item__content > div {
    display: flex;
    /* align-items: center; */
}
.inbox_page .channel_row .pad_tb14 .channel_details,
.inbox_page .swipeable-list-item__content > div .channel_details {
    align-self: center;
}
.inbox_page .dayDivider {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    border-top: 1px solid #dedede;
    padding: 22px 0 10px;
}
.inbox_page .channel_list .channel_row:first-child .dayDivider {
    border-top: none;
}
.inbox_page .channel_icon {
    position: relative;
}
.inbox_page .channel_icon img {
    width: 42px;
    height: 42px;
    background-color: #efefef;
    padding: 10px;
    object-fit: contain;
}
.inbox_page .channel_details {
    width: calc(100% - 136px);
}
.inbox_page .channel_graph {
    width: 94px;
    position: relative;
    min-height: 42px;
}
.inbox_page .channel_graph img {
    width: 84px;
    height: 50px;
    object-fit: contain;
    position: relative;
    background: #f7f7f7;
}
.inbox_page .channel_graph .rightSide_verticalDots {
    /* top: calc(50% - 12px); */
    margin-right: 10px;
    padding: 0 10px;
}
/* .inbox_page.rightSide_verticalDots_dropdownlist {
    margin-top: 84px;
    margin-right: -4px;
} */
.inbox_page.rightSide_verticalDots_dropdownlist {
    padding: 5px 20px;
}
.inbox_page.rightSide_verticalDots_dropdownlist .list-item {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
}
.inbox_page.rightSide_verticalDots_dropdownlist .list-item .icon_holder svg {
    height: 22px;
    width: 20px;
}
.inbox_page.rightSide_verticalDots_dropdownlist .list-item .icon_holder {
    width: 35px;
}
.inbox_page.rightSide_verticalDots_dropdownlist .list-item .list-item-name {
    width: calc(100% - 35px);
}
.inbox_page.rightSide_verticalDots_dropdownlist.lastpopover {
    margin-top: -90px;
    /* margin-right: 4px; */
}

.inbox_page .swipeable-list-item__content > div {
    width: 100%;
    padding: 14px 0;
}
.swipeDelete {
    background: #e00000;
    width: 110px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.swipeDelete svg {
    fill: #fff;
}
/* ================================================== */
.rightSide_verticalDots_dropdownlist {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);

    padding: 5px 16px;
    border-radius: 4px;
    right: 0;
    background: #fff;
    z-index: 2;
    max-width: 80%;
}
.rightSide_verticalDots_dropdownlist .list-item {
    padding: 11px 0;
    font-size: 14px;
    line-height: 16px;
}
.rightSide_verticalDots_dropdownlist .icon_holder {
    width: 43px;
    padding-right: 14px;
}
.rightSide_verticalDots_dropdownlist .icon_holder svg {
    width: 100%;
    height: 19px;
}
.rightSide_verticalDots_dropdownlist .list-item-name {
    width: calc(100% - 43px);
}
/*  End inbox page Style =========================
================================================*/

/*=================================================
    Start dataset page Style ====================*/
.dataset-title-container {
    display: flex;
    justify-content: space-between;
}
.dataset-title-container h2 {
    margin-bottom: 0;
}
.dataset-title-container .text-muted p {
    margin-bottom: 0;
}
.action_list {
    flex-grow: 1;
    scale: 0.9;
    max-width: min-content;
    color: black;
    font-size: 0.875rem;
    font-weight: 400;
    padding-bottom: 20px;
}
.dataset-action {
	  padding: 0 10px;
}
.dataset_page .dataset-assistant .comments {
    padding: 0;
}
.dataset_page .dataset-assistant .alerts {
    padding: 15 0 0 0;
}
.dataset_page .dataset-assistant .related {
    padding: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}
.dataset_page .related .graph_detail {
    padding-left: 30px;
}
.dataset_page .graph_detail {
	  padding-left: 50px;
}
.dataset_page .gridbox .title_showmore_link h2 {
    width: calc(100% - 60px);
    word-break: break-word;
}
.dataset_page .gridbox .title_showmore_link .showmore_link {
    width: 60px;
    color: #a5a5a5;
    margin-bottom: 12px;
}
.dataset_page .gridbox .title_showmore_link .showmore_link:hover {
    text-decoration: none;
    color: #a5a5a5;
}
.dataset_page .gridbox .text-muted.position-relative {
    left: 0;
    bottom: 0;
}
.datasetContent {
    display: flex;
}
.datasetContent-Dataset {
    flex-grow: 1;
}
.datasetContent-DatasetAssistant {
    max-width: 30%;
}
.datasetDesc.show-more {
    height: 250px;
    overflow: hidden;
}
.datasetDesc .lessMoreBtn {
    width: 50px;
    text-align: right;
    padding-top: 2px;
}
.datasetDesc .markdown-content {
    width: calc(100% - 50px);
}
.datasetDesc .markdown-content h1 {
    font-size: 2em;
    font-weight: 600;
    border-bottom: none;
    padding-bottom: 0.3em;
}
.datasetDesc .markdown-content h2 {
    font-size: 1.5em;
    font-weight: 600;
	  border-bottom: none;
    padding-bottom: 0.3em;
}
.datasetDesc .markdown-content h3 {
    font-size: 1.25em;
    font-weight: 600;
}
.datasetDesc .markdown-content h4 {
    font-size: 1em;
    font-weight: 600;
}
.datasetDesc .markdown-content code {
    color: inherit;
    font-size: inherit;
    /* font-weight: 500;
    font-family: inherit; */
}
/* START List View */

.dataset_page .listView.custom_container {
    padding-left: 10px;
    padding-right: 10px;
}
.dataset_page .listView .gridbox .related-dataset-card {
    display: block;
    width: 100%;
}
.dataset_page .listView .gridbox {
    max-width: 100%;
    flex: 0 0 100%;
    /* max-height: 250px; */
    /* margin-bottom: 20px; */
    margin-bottom: 15px;
    overflow: hidden;
    padding: 0;
}
.dataset_page .listView .gridbox .graph_holder,
.dataset_page .listView .gridbox .graph_detail {
    display: inline-block;
}
.dataset_page .listView .gridbox .graph_holder img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.dataset_page .listView .gridbox .graph_detail {
    background: none;
    padding-left: 15px;
    margin-top: 0;
    position: relative;
    height: 100%;
}
.dataset_page .listView .gridbox .graph_detail .text-muted {
    position: relative;
    bottom: 0;
    left: 0;
    font-size: 12px;
    line-height: 17px;
}

/* END List View */

.alert .raised {
    font-size: 15px;
    line-height: 17px;
    color: #b7b7b7;
    margin: 0;
}

.alert .detail {
    margin: 0;
}

.action_list svg,
.action_list i {
    margin-bottom: 5px;
}
.action_list svg {
    height: 26px;
}
.action_list i {
    font-size: 22px;
    line-height: 26px;
}
.action_list i.active {
    color: #faa128;
}
.action_list span.active {
    color: #faa128;
}
.action_list .disable {
    pointer-events: none;
}

.add_comment img {
    height: 42px;
    width: 42px;
    object-fit: cover;
}
.add_comment .form-group {
    margin-bottom: 0;
    width: calc(100% - 42px);
}
.add_comment .form-control {
    padding: 0 0 0 15px;
    margin: 2px 0;
    border: none;
}
.add_comment .form-control.pad_0 {
    padding: 0;
}
.add_comment .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a5a5a5 !important;
}
.add_comment .form-control::placeholder {
    color: #a5a5a5 !important;
}

.add_comment .rta .form-control.pad_tb8 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.add_comment .rta .rta__list {
    padding: 12px;
    border-radius: 3px;
    box-shadow: 0 8px 16px -4px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
    border: none;
    margin-top: 10px;
}
.add_comment .rta .rta__list .rta__item:not(:last-child) {
    border-bottom: none;
}

.add_comment .rta .rta__list .rta__entity {
    padding: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
}
.add_comment .rta .rta__list .rta__entity.rta__entity--selected {
    background: rgba(9, 30, 66, 0.04);
    color: #000;
    /* border-radius: 4px; */
}

.comment .comment_content span {
    font-weight: 700;
}
.comment .comment_content {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    word-break: break-word;
    overflow-wrap: break-word;
    width: calc(100% - 90px);
}
.comment .duration {
    font-size: 15px;
    line-height: 17px;
    color: #b7b7b7;
    width: 70px;
}
.comment_action {
    padding: 10px 0 0 3px;
}
.comment_action i {
    font-size: 18px;
    margin-bottom: 0;
}
.comment_action span {
    padding: 0 12px 0 5px;
    font-size: 13px;
    line-height: 18px;
}
.comment .sub_comment {
    padding-left: 35px;
    padding-top: 10px;
}

.viewmore_replies .triangle {
    position: relative;
    height: 5px;
    width: 5px;
    display: inline-block;
    margin-right: 10px;
    top: -2px;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #f29048 transparent transparent transparent;
}
.viewmore_replies.hide .triangle {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #f29048 transparent;
}
.viewmore_replies {
    background: transparent;
    border: none;
    color: #f29048;
}
/*  End dataset page Style =========================
==================================================*/

/*==================================================
    Start library page Style =====================*/
.library_page {
    padding: 0 10px;
}
.library_page.pad_r0 {
    padding-right: 0px;
}
.story_list {
    font-size: 14px;
    line-height: 17px;
}
.story_list a.story_box {
    padding: 15px 15px 15px 0px;
    border-bottom: 1px solid #dedede;
    color: var(--primary-color);
}
.story_list a.story_box:hover {
    text-decoration: none;
}
.story_list a.story_box:hover p {
    color: var(--primary-color);
}

.story_list .text-muted {
    font-size: 12px;
    color: #a5a5a5;
}
.story_list a.story_box .rightSide_verticalDots {
    top: 15px;
}
.library_page_divider {
    padding: 0 30px;
}
/* ------------------------------ */
.recentLibrarySlider_holder {
    padding-left: 20px;
}
.recentLibrarySlider {
    /* margin: 0 -7px; */
    padding-top: 15px;
    /* width: calc(100% + 7px); */
    padding: 10px 0;
    margin-right: -50px;
}
.recentLibrarySlider .slick-track {
    margin-left: 0;
}
.recentLibrarySlider .slick-arrow {
    display: none !important;
}
.recentLibrarySlider .slick-slide {
    /* width: 140px; */
    padding-right: 14px;
}
.recentLibrarySlider .gridbox {
    width: 100%;
    padding: 0;
}
.recentLibrarySlider .gridbox .rightSide_verticalDots {
    right: 3px;
}
.recentLibrarySlider .graph_detail {
    padding: 0 12px 0 0;
    background: none;
}
.recentLibrarySlider .graph_detail h2 {
    font-size: 15px;
    line-height: 18px;
    word-break: break-word;
}
/*  End library page Style =========================
==================================================*/

/*==================================================
    Start invite page Style =====================*/
.inviteList {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}

.inviteList .status {
    text-transform: lowercase;
}
.inviteList .status::first-letter {
    text-transform: uppercase;
}
.inviteList .status .revoked {
    color: #C40A0A;
}
.inviteList .status .pending {
    color: #f29048;
}
.inviteList .status .accepted {
    color: #019612;
}
.inviteListRow.rowHead {
    font-weight: 500;
    border-top: 1px solid #d0d0d0;
}
.inviteList .inviteListRow {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #d0d0d0;
}
.inviteList .inviteListRow:not(.rowHead):hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.inviteButtons {
    text-align: right;
}
.inviteButtons .replaceByLoader button {
    opacity: 0;
}
.inviteButtons .inviteButtonHolder {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}
.inviteList .inviteButtonHolder:last-child {
    margin-right: 0px;
}
.inviteList .btn-warning {
    padding: 1px 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 4px;
}
.inviteButtons .spinner_btnHolder {
    position: absolute;
    z-index: 9;
    min-width: auto;
    top: 0;
    left: 0;
    right: 10px;
    bottom: 0;
}
.inviteButtons .spinner_btnHolder .spinner-border {
    height: 26px;
    width: 26px;
}

@media screen and (max-width: 767px) {
    .inviteList .inviteListRow {
        display: block;
    }
    .inviteList .date {
        margin-top: 5px;
    }
    .inviteList .status {
        text-align: right;
    }
    .inviteList .inviteButtons {
        text-align: left;
        margin-top: 10px;
    }
}
/*  End invite page Style =========================
==================================================*/

@media screen and (max-width: 767px) {
    .newInsightBox {
        top: 70px;
    }

    .custom_container.gridview {
        padding-left: 0;
        padding-right: 0;
    }

    .dataset-card {
        min-height: min-content;
    }

    .gridview .graph_detail {
        padding-left: 70px;
        padding-right: 15px;
        background-position: 15px 0;
    }

    .gridbox .text-muted {
        position: relative;
        bottom: 0;
        left: 0;
    }

    /* start for explore page */
    .explore_page .subtitle {
        padding: 0;
    }
    /* end for explore page */

    /* start for inbox page */
    .inbox_page .channel_list {
        padding: 0;
    }
    .inbox_page .channel_row {
        /* padding: 11px 0; */
        border-bottom: none;
    }
    .inbox_page .channel_icon:before {
        left: -12px;
    }
    /* end for inbox page */

    /* start for library page */
    .library_page {
        padding: 0 !important;
    }
    .library_page_divider {
        padding: 0;
    }
    .library_page .story_list a.story_box {
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: none;
    }
    .story_list .story_box {
        padding: 11px 15px 11px 0px;
        border-bottom: none;
    }
    /* end for library page */

    /* start for channel page */
    .channel_page .channel_row {
        padding-left: 0;
        padding-right: 0;
    }
    .channel_page .channel_list {
        margin: 0 -4px;
    }
    /* end for channel page */

    /* start for dataset page */
    .dataset_page .custom_container.mobview_dataset,
    .dataset_page .mobview_dataset .gridbox {
        padding: 0;
    }
    .dataset_page .mobview_dataset .graph_detail {
        padding-left: 65px;
    }
    .dataset_page .mobview_dataset .dataset-assistant {
        padding-bottom: 60px;
    }
    .dataset_page .mobview_dataset .custom_container.listView .gridbox {
        margin-bottom: 20px;
    }
    .dataset_page .mobview_dataset .listView .graph_detail {
        padding-left: 15px;
    }
    .dataset_page .mobview_dataset .shownore_link {
        color: #a5a5a5;
        padding: 12px 20px 25px;
        display: block;
    }
    .dataset_page .mobview_dataset .shownore_link:hover {
        text-decoration: none;
    }
    .datasetContent {
        display: block;
    }
    .datasetContent-Dataset {
        flex-grow: 0;
    }
    .datasetContent-DatasetAssistant {
        max-width: 100%;
    }
    /* end for dataset page */

    /* start for slick slider roundedListItemSlider */
    .slick-slider.roundedListItemSlider {
        padding: 0;
    }
    .slick-slider.roundedListItemSlider .slick-arrow {
        display: none !important;
    }
    .slick-slider.buttonListSlider {
        padding-left: 0;
        padding-right: 0;
    }
    .slick-slider.buttonListSlider .slick-arrow.slick-prev,
    .slick-slider.buttonListSlider .slick-arrow.slick-next {
        display: none !important;
    }
    /* end for slick slider roundedListItemSlider */
}

/*#########################################################################
#########################################################################*/

@media screen and (min-width: 768px) {
    .gridview .gridbox .graph_holder {
        padding: 0;
        height: 30.029vw;
        min-height: auto;
    }
    /*====================================*/
    .largCardView .gridbox {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .listView .gridbox {
        max-width: 100%;
        flex: 0 0 100%;
    }
    /*====================================*/
    .listView .gridbox .graph_holder,
    .listView .gridbox .graph_detail {
        display: inline-block;
        /* float: left; */
    }
    .listView .gridbox .graph_holder img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .dataset_page .listView .gridbox .graph_holder,
    .dataset_page .listView .gridbox .graph_detail {
        float: left;
        margin: 0;
    }
    .listView .gridbox .graph_holder {
        width: 100%;
    }
    .listView .gridbox .graph_detail {
        /* padding-left: 15px; */
        margin-top: 0;
        position: relative;
        height: 100%;
    }

    .listView .gridbox .text-muted {
        position: relative;
        bottom: 0;
        left: 0;
    }
}

/*#########################################################################
#########################################################################*/
@media screen and (min-width: 992px) {
    .gridview .gridbox .graph_holder {
        height: 22vw;
    }
}
/*#########################################################################
#########################################################################*/

@media screen and (min-width: 768px) and (max-width: 991px) {
    .dataset_page .listView .gridbox .graph_detail .relatedDetails {
        width: 100% !important;
        padding: 5px 0 0 0 !important;
    }
}

.MuiInput-underline:before{
    border-bottom: none!important;
}

/* HELP PAGES CSS #########################################################
#########################################################################*/


.help-table td{
    border: none;
}

/* LIBRARY CSS ############################################################
#########################################################################*/

.channel-row-info{
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.channel-info{
    margin-top: auto;
    margin-bottom: 0px;
    padding-top: 5px;
}

.library-row-hover:hover{
    border-color: #545454;
    cursor: pointer;
}

.library-navigation {
    font-size: 16px;
    padding-top: 20px;
}

.library-image-holder {
    float: left;
    padding-right: 15px;
}


/* PAGE TITLE CSS #########################################################
#########################################################################*/

.page-title{
    font-size: 36px;
    color: var(--secondary-color);
}

.profile_dropdown .dropdown-menu.dropdown-menu-right {
    transition: all 0.5s ease-in-out;
    display: block;
    right: -360px;
}
.profile_dropdown.show .dropdown-menu.show.dropdown-menu-right {
    right: 0;
}
.profile_dropdown .dropdown-menu {
    padding: 0;
    width: 330px;
    border-radius: 0;
    height: calc(100vh - 54px);
    border: none;
    top: var(--toolbar_height);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
}
.profile_dropdown .profile_dropdown_head {
    padding: 18px 25px;
}
.profile_dropdown .profile_image {
    height: 45px;
    width: 45px;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 12px;
}
.profile_dropdown .profile_name {
    font-size: 17px;
    line-height: 17px;
    color: var(--primary-color);
}
.profile_dropdown .edit_profile {
    font-size: 15px;
    line-height: 17px;
    color: #a7a7a7;
    margin-top: 5px;
}
.profile_dropdown .dropdown-item img {
    height: 15px;
    width: 20px;
    margin-left: 3px;
    margin-right: 7px;
}
.profile_dropdown .dropdown-item.profile_name,
.profile_dropdown .dropdown-item.edit_profile {
    display: inline;
    padding: 3px;
}
.profile_dropdown .edit_profile:hover,
.profile_dropdown .edit_profile:focus {
    color: #a7a7a7;
}
.profile_dropdown a {
    color: var(--primary-color);
    font-size: 16px;
    line-height: 22px;
}
.profile_dropdown .dropdown-item:hover,
.profile_dropdown .dropdown-item:focus,
.profile_dropdown .dropdown-item.active {
    background: transparent;
    color: var(--primary-color);
    text-decoration: none;
}

.profile_dropdown .dropdown-item svg {
    color: #a7a7a7;
    fill: #a7a7a7;
    width: 20px;
    margin-right: 15px;
}
.profile_dropdown .dropdown-item:hover,
.profile_dropdown .dropdown-item.active {
    font-weight: 500;
}
.profile_dropdown .dropdown-item:hover svg,
.profile_dropdown .dropdown-item.active svg {
    fill: var(--primary-color);
}
.profile_dropdown .dropdown-item {
    padding: 13px 25px;
    display: flex;
    align-items: center;
    white-space: unset;
}

.profile_dropdown .workspace_dropdown span {
    color: var(--primary-color);
}

.profile_dropdown .workspace_dropdown .dropdown-menu {
    margin: 5px 0;
    padding-left: 59px;
    padding-bottom: 15px;
    /* border-radius: 0; */
    width: 100%;
    border-width: 0 0;
    position: relative;
    height: auto;
    box-shadow: none;
    top: 0;
}
.profile_dropdown .workspace_dropdown img {
    height: 13px;
    width: 7.7px;
    float: right;
    right: 25px;
}
.profile_dropdown .workspace_dropdown.show img {
    transform: rotate(-90deg);
}
.profile_dropdown .workspace_dropdown .dropdown-menu .dropdown-item {
    padding: 0;
    font-size: 15px;
    line-height: 30px;
}

.collapse:not(.show) h1 {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.collapse:not(.show) {
    overflow: hidden;
    text-overflow: ellipsis;
}

.brand {
    padding: 0px;
    margin: 0 13px 0 18px;
}

.brand img {
    height: 30px;
    margin: 0;
}

.brand span {
    font-size: 20px;
    left: 105px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.navigate-brand {
    cursor: pointer;
}

.toolbar {
    z-index: 100 !important;
    border-bottom: 1px solid var(--border_color_grey);
    height: var(--toolbar_height);
    padding: 0;
    text-transform: capitalize;
}

.toolbar i {
    /* font-size: 25px; */
    /* center vertically */
    margin: 0px 5px 0px 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.toolbar a {
    color: rgba(0, 0, 0, 0.5);
}

.toolbar form {
    width: 100%;
    padding: 0;
}
.toolbar .input-group {
	box-shadow: none;
    color: #b1b1b1;
    transition: box-shadow 300ms ease-out;
}
.toolbar .toolbar-search-input:focus {
    box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%), 0 1px 3px 1px rgb(65 69 73 / 15%);
    border-radius: 8px;
}
.toolbar .toolbar-search-input {
    border: none;
    background: transparent;
	font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    height: auto;
    padding-left: 45px;
}
.toolbar .toolbar-search-input input:-ms-input-placeholder {
    color: #b1b1b1;
    font-weight: 400;
    opacity: 1; /* Firefox */
}
.toolbar .toolbar-search-input input::placeholder {
    color: #b1b1b1;
    font-weight: 400;
    opacity: 1; /* Firefox */
}

.toolbar input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b1b1b1;
    font-weight: 400;
}

.toolbar input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b1b1b1;
    font-weight: 400;
}

.toolbar .toolbar-search {
    border-radius: 8px;
    background: #f1f3f4;
    margin: auto 140px auto 0;
    display: flex;
    justify-content: flex-start;
}

/* suggestionsContainer */
.toolbar-search > div:first-of-type {
    flex: 1 1;
}

/* tagsContainer */
.toolbar-search > div:nth-of-type(2) {
    width: 0%;
}

.toolbar .toolbar-search-icon {
  min-width: 40px;
}

.toolbar .toolbar-search-icon img {
    width: 14px;
    object-fit: contain;
}

.toolbar .suggestions input {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.toolbar .toolbar-search-suggestionlist {
	max-height: none;
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%), 0 1px 3px 1px rgb(65 69 73 / 15%);
}

@media screen and (max-width: 767px){
  .toolbar .toolbar-search {
    margin: auto 150px auto 0 !important;
  }
  .toolbar .toolbar-search-input {
    padding-left: 25px;
  }
}

.hamburgermenu_holder {
    width: var(--sidemenu_width);
    text-align: center;
}
.hamburger_menu {
    height: 30px;
    width: 34px;
    border: 1px solid #c5c5c5;
    padding: 5px 6px;
    border-radius: 4px;
    cursor: pointer;
    /* margin-right: 15px; */
    display: inline-block;
    margin-top: 4px;
}
.hamburger_menu span {
    width: 20px;
    height: 2px;
    margin: 3px 0;
    background: #c5c5c5;
    display: block;
    text-align: center;
}

.toolbar-close {
    left: 30px;
}

.toolbar-search {
    right: 55px;
}
.toolbar-user {
    right: 20px;
    color: #a7a7a7;
    padding: 0 0 0 15px;
    align-items: center;
}
.toolbar-user img {
    width: 24px;
    height: 19px;
}

.toolbar-user .btn-link {
    padding: 0 12px 0 0;
}
.user_image img {
    height: 34px;
    width: 34px;
    object-fit: cover;
    border-radius: 50%;
}

.toolbar-notification {
    position: relative;
    display: inline-flex;
    min-width: 31px;
}
.toolbar-notification svg {
    height: 19px;
    width: auto;
}
.toolbar-notification:hover {
    text-decoration: none;
}
.toolbar-notification .redcount {
    background: #e50303;
    color: #fff;
    border-radius: 10px;
    min-width: 13px;
    height: 13px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 13px;
    font-size: 8px;
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: -2px;
    left: -6px;
    margin-right: 6px;
}

.toolbar .dropdown-toggle::after {
    display: none;
}
.dropdown-toggle.nav-link {
    padding: 0;
}
.profile_dropdown {
    display: inline-flex;
    align-items: center;
    padding: 10px;
}
.toolbar-profile-items {
	 position: absolute;
	 right: 0;
	 top: 0;
	 display: inline-flex;
	 height: var(--toolbar_height);
}

/* fix padding around search after resize */
@media screen and (max-width: 767px) {
    .toolbar-close {
        left: 15px;
    }

    /* -------------- */
    .navbar.toolbar {
        flex-wrap: inherit;
    }
    .toolbar {
        padding: 10px 0;
    }
    .brand {
        width: auto;
        padding: 0 15px;
        margin: 0;
    }
    .brand img {
        height: 38px;
        width: 38px;
    }
    /* .collapse:not(.show) {
    display: flex;
    flex-basis: auto;
    max-width: calc(100% - 240px);
  } */

    .toolbar .toolbar-search-icon {
      display: none;
    }
    .toolbar input {
      padding-left: 20px;
    }
    .toolbar-notification svg {
        height: 22px;
        width: auto;
    }
    .toolbar-user img {
        width: 27px;
        height: 22px;
    }
    .user_image img {
        height: 35px;
        width: 35px;
    }
    .toolbar-notification .redcount {
        top: -1px;
        left: -7px;
    }
    /* -------------- */

    .profile_dropdown .profile_dropdown_head {
        padding: 0px 25px 10px;
    }
    .profile_dropdown .close_profiledropdown {
        padding-bottom: 0;
    }
    .profile_dropdown .close_profiledropdown img {
        width: 14px;
        position: relative;
        right: -8px;
    }
    .profile_dropdown .dropdown-menu {
        width: 100vw;
        top: -12px;
    }

    .profile_dropdown .dropdown-menu.dropdown-menu-right {
        right: -100vw;
    }
    /* hide header */
    .noHeader {
        display: none;
    }
}

.toolbar-invite {
    margin-left: 0 !important;
}

.toolbar-name-mobile{
    margin-left: 4rem;
    justify-content: flex-start;
}

.toolbar-invite {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.toolbar-name h2 span {
    color: #454F66 !important;
}

.toolbar-name-mobile h2 span {
    color: #454F66 !important;
}

@media screen and (max-width: 767px){
    .toolbar-name{
        justify-content: flex-start;
        transform: scale(0.9);
        min-width: 42%;
        margin-left: -1rem;
    }
    .toolbar-name-mobile{
        justify-content: flex-start;
        transform: scale(0.9);
        min-width: 42%;
        margin-left: -1rem !important;
    }
}

@media screen and (max-width: 540px){
    .workspace-name-mobile{
        display: none !important;
    }
}
.sidebar {
    margin: 0px !important;
    transition: all 0.5s ease-in-out;
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    text-transform: capitalize;
    white-space: nowrap;
}
.sidebar-expanded {
    /* flex: 0 0 80px; */
    /* max-width: 80px; */
    flex: 0 0 var(--sidemenu_width);
    max-width: var(--sidemenu_width);
}
.sidebar-narrow {
    flex: 0 0;
    /* max-width: 80px ; */
    max-width: var(--sidemenu_width);
}
.sidebar-nav-expanded {
    /* width: 80px; */
    width: var(--sidemenu_width);
    left: 0;
}
.sidebar-nav-narrow {
    width: var(--sidemenu_width);
    left: -82px;
}
.sidebar-navbar {
    margin: 0px;
    padding: 0px;
}
.sidebar-nav {
    border-right: 1px solid var(--border_color_grey);
    height: 100vh;
    position: relative;
    transition: width 0.5s ease-in-out;
    background: #455066;
}

.lab {
    background: #fcbd33 !important;
}



/************************************************************/
.sidebar-link,
.expand-collapse {
    padding: 0.5rem;
}
.workspace-name.nav-link,
.sidebar-link.nav-link {
  text-align: center;
}

.footer-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-navbar .workspace-name,
.sidebar-navbar .sidebar-link, 
.sidebar-navbar .expand-collapse,
.footer-navbar .footer-link {
    color: #a7a7a7 !important;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.sidebar-navbar .lab .workspace-name,
.sidebar-navbar .lab .sidebar-link, 
.sidebar-navbar .lab .expand-collapse, 
.footer-navbar .lab .footer-link {
    color: var(--secondary-color) !important;
}

.sidebar-navbar .sidebar-link i, 
.footer-navbar .footer-link i {
    font-size: 25px;
}

.expand-name:hover{
    color: #fff!important;
}

.footer-navbar .footer-link.active,
.footer-navbar .footer-link:hover,
.sidebar-navbar .sidebar-link.active,
.sidebar-navbar .sidebar-link:hover,
.footer-navbar .footer-link.active div i,
.footer-navbar .footer-link:hover div i,
.sidebar-navbar .sidebar-link.active div i,
.sidebar-navbar .sidebar-link:hover div i,
.footer-navbar .footer-link.active div,
.footer-navbar .footer-link:hover div,
.sidebar-navbar .sidebar-link.active span,
.sidebar-navbar .sidebar-link:hover span {
    color: #fbfcfc !important;
    background-color: var(--secondary-color);
}

.footer .linkIcon_holder {
    width: inherit !important;
}

.linkIcon_holder {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
}
.linkIcon_holder .count {
    position: absolute;
    z-index: 9;
    top: -3px;
    right: -25px;
    left: 0;
    margin: auto;
    background: #e50303;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    font-size: 10px;
    color: #fff;
}
/************************************************************/

/* fix padding under Toolbar after resize */
@media screen and (max-width: 767px) {
  .workspace-name {
    padding: 20px 0px 0px 0px !important;
  } 
	.footer {
        position: fixed;
        width: 100%;
        height: 60px;
        border-top: 1px solid #d9d9d9;
        padding: 0;
        left: 0;
        bottom: 0;
        background-color: var(--secondary-color);
        margin: 0;
        text-align: center;
        z-index: 99;
    }
    .footer-navbar,
    .footer-nav {
        background: var(--secondary-color);
        width: 100%;
        padding: 0;
    }
    .footer-link {
        width: calc(100% / 6);
        text-align: center;
        flex-grow: 1;
        flex-basis: 1;
    }
}

@media screen and (min-width: 767px){
    .sidebar-nav-narrow {
        width: var(--sidemenu_width);
        left: 0px;
    }
}

.sidebar-expanded {
    flex: 0 0 160px;
    max-width: 225px;
}
.sidebar-narrow {
    flex: 0 0;
    /* max-width: 80px; */
    max-width: var(--sidemenu_width);
}
.sidebar-nav-expanded {
    width: 225px;
    left: 0;
}
.sidebar-navbar {
    margin: 0px;
    padding: 0px;
}

.sidebar {
    overflow: hidden;
}

.workspace-name.nav-link,
.sidebar-link.nav-link,
.expand-collapse {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    height: 60px;
}

.expandedName {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-arrow {
    transform: scale(0.8);
    border: 1px solid #343f58;
    border-radius: 60%;
}

.sidebar-arrow:hover {
    background-color: #a7a7a7;
}

.workspaces-menu div.dropdown-menu.show{
    position: fixed !important;
    inset: 0px auto auto 0px !important;
    top: 12rem!important;
    left: 4rem!important;
    margin: 0px !important;
    transform: translate(0px, 42px) !important;
}

.workspace_dropdown .workspaces-arrow {
    height: 13px;
    width: 7.7px;
    margin: auto 10px;
    transform: rotate(180deg);
}


.workspace_dropdown .workspaces-arrow-open {
    height: 13px;
    width: 7.7px;
    margin: auto 10px;
    transform: rotate(-90deg);
}

@media screen and (max-width: 767px){
    .workspace_dropdown .workspaces-arrow .dropdown_icon {
        height: 13px;
        width: 7.7px;
        float: right;
        right: 5px;
        top: 15px;
        transform: rotate(-180deg);
    }
    
    .workspaces-menu div.dropdown-menu.show{
        top: 68% !important;
    }

    .workspace_dropdown.show .workspaces-arrow .dropdown_icon {
        transform: rotate(-90deg);
    }
}

.dropdown-toggle::after{
    display: none !important;
}

.profile-menu-medium {
	  width: 100%;
    display: inline-block;
    white-space: nowrap;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.workspace-name:hover{
    background-color: none !important;
}

.expand-collapse-arrow:hover{
    opacity: 0.5;
}

li a.nav-link.dropdown-item:active, 
div a.dropdown-item:active , li div a.dropdown-toggle.nav-link span.dropdown-item.workspaces-arrow.navbar-text:active, li div a.dropdown-toggle.nav-link:active {
    background-color: unset !important;
    outline: none !important;
    color: unset !important;
}

.menu-chain-icon {
    width: 30px !important;
    height: 30px !important;
    color: #3b4458 !important;
    fill: #3b4458 !important;
    margin-right: 10px !important;
    margin-left: -20px !important;
}

.workspace_dropdown .dropdown-menu {
    margin: 5px 0;
    padding: 0 !important;
    /* border-radius: 0; */
    width: 100%;
    border-width: 0 0;
    position: relative;
    height: auto;
    box-shadow: none;
    top: -40px;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item-active {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa !important;
}

.dropdown-item span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa !important;
}

div.workspace_dropdown div.dropdown-menu.show{
    position: relative !important;
    inset: -42px auto auto 0px !important;
}

.nav-link.dropdown-item:active {
	  background-color: unset !important;	
}

.dropdown-item {
   	text-transform: capitalize;
}

.dropdown-subitem {
	  padding-left: 40px;
	  cursor: pointer;
}

a.nav-link.dropdown-item, span.dropdown-item.workspaces-arrow.navbar-text{
    min-height: 43px;
}
a.nav-link.dropdown-item:hover{
    border-radius: 5px;
}

span.dropdown-item.workspaces-arrow.navbar-text:hover{
    border-radius: 5px;
}

.tasks-in-progress-icon{
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    border-radius: 100%;
    background-color: #455066;
    width: 40px;
    height: 40px;
    font-size: 15px;
    color: white !important;
    padding-top: 11px;
    line-height: 16px;
}

.circle-border {
  position: relative;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: linear-gradient(270deg, #009579 50%, transparent 50%), linear-gradient(0deg, #009579, lightgray 50%)
}

.tasks-in-progress-icon:hover{
    color: white;
}
.workspace-url-image {
    width: 30px;
    height: 30px;
    border-radius: 10%;
}
.workspace-menu-header {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px 16px 10px 16px;
}
.workspace-menu-name {
  color: #454F66;
  font-size: 16px;
  font-weight: 500;
  width: 90vw;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: capitalize;
}
.workspace-menu-name .linkIcon_holder {
	width: 60px;
	display: flex;
	justify-content: center;
}
.workspaces-menu-header {
	color: #454F66 !important;
	font-size: 19px;
	line-height: 23px;
	font-weight: 500;
	padding: 10px 16px 10px 16px;
}
.animated {
    opacity: 0;
}

.fadeIn.animated {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out;
}

.fadeInUp.animated {
    opacity: 1;

    -webkit-transform: translate3d(0, 30px, 0);
    -webkit-moz: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
    -webkit-transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
    transform: translate3d(0, 0, 0);
}

/* ======================================================== */
.notification-card-parent {
    position: fixed;
    z-index: 99999;
    bottom: 25px;
    left: 20px;
    right: 20px;
    /* padding: 15px 20px 30px; */
    /* background-color: #fff; */
}

.notification-card {
    background: #fff;
    border: 1px solid #a7a7a7;
    border-radius: 5px;
    color: #191919;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    padding: 10px 0 15px;
}

.notification-card .close-notification {
    right: 8px;
    top: 0px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
}

.notification-card .close-notification img {
    height: 12px;
    width: 12px;
}

.notification-card .notification-logo {
    height: 45px;
    width: 45px;
    object-fit: contain;
}

.notification-card .popper-triangle-bottom {
    height: 15px;
    width: 15px;
    border: solid #a7a7a7;
    position: absolute;
    z-index: 1;
    transform: rotate(45deg);
    border-width: 0 1px 1px 0;
    bottom: -8px;
    background: #fff;
    left: 50%;
}

.notification-card .shareIcon {
    height: 20px;
    width: 25px;
    object-fit: contain;
    margin-top: -4px;
    cursor: pointer;
}
/* ======================================================== */

/* fix padding under Toolbar after resize */
@media screen and (max-width: 767px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (min-width: 992px) {
}

.workbench {
    margin: 0px !important;
    padding: var(--toolbar_height) 0px 0px 0px;
    min-height: 100vh;
}

.workbench-container {
    padding: 0px !important;
}

.app-action {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 100px!important;
    width: 100px!important;
    margin: 30px;
    background-color: #f08f3f;
    border-radius: 50%;
}

.app-action:hover {
    background: #a7a7a7 !important;
}

/* fix padding under Toolbar after resize */
@media screen and (max-width: 767px) {
    .app-action {
        bottom: var(--toolbar_height);
    }
    .removeHeader .workbench {
        padding-top: 0!important;
    }
}
/* @media screen and (min-width:768px) and (max-width: 991px) {
  .workbench { padding-top: 68px; }
}
@media screen and (min-width: 992px) {
  .workbench { padding-top: 68px; }
} */

.hasSearchForm.collapse:not(.show) {
    display: none;
}

/*#########################################################################
#########################################################################*/

@media screen and (min-width: 768px) {
    .toggleTopOnScroll .stickyTop {
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        /* left: var(--sidemenu_width); */
        /* width: 100%; */
        background: #fff;
    }
    /* .toggleTopOnScroll .toolbar.navbar.fixed-top.hide {
        top: -100px;
    }
    .toggleTopOnScroll .toolbar.navbar.fixed-top.show {
        top: 0;
    } */

    .toggleTopOnScroll .hasSearchForm.show.hide-sticky,
    .toggleTopOnScroll .hasSearchForm.hide-sticky,
    /* .toggleTopOnScroll .toolbar.navbar.fixed-top.hide, */
    .toggleTopOnScroll .stickyTop.hide {
        opacity: 0;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }

    .toggleTopOnScroll .hasSearchForm.show.show-sticky,
    .toggleTopOnScroll .hasSearchForm.show-sticky,
    /* .toggleTopOnScroll .toolbar.navbar.fixed-top.show, */
    .toggleTopOnScroll .stickyTop.show {
        opacity: 1;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }

    .toggleTopOnScroll .stickyTop.hide {
        top: -100px;
    }
    .toggleTopOnScroll .stickyTop.show {
        top: var(--toolbar_height);
    }
    .toggleTopOnScroll .rightside_pageContent.hasStickyTags {
        padding-top: 80px;
    }
}

/*#########################################################################
#########################################################################*/

@media screen and (max-width: 767px) {
    .toggleTopOnScroll .stickyTop,
    .toggleTopOnScroll .toolbar.navbar.fixed-top {
        animation: 1.5s ease 0s normal forwards 1 fadein;
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        40% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .toggleTopOnScroll .toolbar.navbar.fixed-top,
    .stickyBack {
        top: 0;
    }

    .toggleTopOnScroll .stickyTop {
        top: 60px;
    }
    .toggleTopOnScroll .stickyTop,
    .stickyBack {
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        width: 100%;
        background: #fff;
    }
    .toggleTopOnScroll .toolbar.navbar.fixed-top.hide,
    .stickyBack.hide {
        top: -100px;
    }
    .toggleTopOnScroll .toolbar.navbar.fixed-top.show,
    .stickyBack.show {
        top: 0;
    }

    .toggleTopOnScroll .hasSearchForm.show.hide-sticky,
    .toggleTopOnScroll .hasSearchForm.hide-sticky,
    .toggleTopOnScroll .toolbar.navbar.fixed-top.hide,
    .toggleTopOnScroll .stickyTop.hide,
    .stickyBack.hide {
        opacity: 0;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }

    .toggleTopOnScroll .hasSearchForm.show.show-sticky,
    .toggleTopOnScroll .hasSearchForm.show-sticky,
    .toggleTopOnScroll .toolbar.navbar.fixed-top.show,
    .toggleTopOnScroll .stickyTop.show,
    .stickyBack.show {
        opacity: 1;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }

    .toggleTopOnScroll .stickyTop.hide {
        top: -100px !important;
    }
    .toggleTopOnScroll .stickyTop,
    .toggleTopOnScroll .stickyTop.show {
        top: 60px;
    }
    .toggleTopOnScroll .rightside_pageContent.hasStickyTags {
        padding-top: 80px;
    }
    /*END sticky content*/
    /* ----------------------- */
}

.spinner {
    position: absolute !important;
    z-index: 0 !important;
}

.spinner~* {
    height: 100%;
    min-height: inherit;
}

.spinner.active~* {
    visibility: hidden;
}
.apikey-list-row {
    padding: 10px;
    border-bottom: 1px solid #d0d0d0;
}

.pointer .MuiInputBase-root, .pointer .MuiInputBase-input {
    cursor: pointer;
}

.MuiButton-contained {
	max-height: 40px;
}
.vertical-center {
    display: flex;
    align-items: center;
}

.inline-vertical-center {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.row-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dataRow-container {
    display: flex;
    margin: auto auto 20px;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.metrics-chart {
    height: 20vh;
}

.row-info-container , .source-search{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    align-items: flex-start;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.source-search{
    height: auto;
    padding: 15px !important;
}

.collapse-pipeline-datastore{
    border-radius: 10px !important;
}

.row-info-container .row-data {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.row-info-container .pipeline-row-data {
    width: 100%;
    position: relative;
}

.image-holders {
    max-width: 100%;
    overflow: auto;
    grid-gap: 10px;
    gap: 10px;
}

.row-data .image-holder,
.pipeline-row-data .image-holder {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: var(--image-holder);
    height: var(--image-holder);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.image-holder.overlay,
.image-holder .overlay,
.image-holder :not(.overlay) {
    transition: 0.2s ease;
}

.image-holder .overlay {
    position: absolute;
    background-color: var(--secondary-color);
    color: white;
    padding: 0.25em 0;
    opacity: 0;
}

.image-holder.overlay:hover {
    border-color: rgba(45, 50, 66, 0.8);
}

.image-holder.overlay:hover > :not(.overlay) {
    opacity: 0.1;
}

.image-holder.overlay:hover > .overlay {
    opacity: 0.8;
}

.image-holder-small {
    width: var(--image-holder-small) !important;
    height: var(--image-holder-small) !important;
}

.image-holder > * {
    min-height: 0;
    width: inherit;
    max-width: 100%
}


.pipeline-row-data .pipeline-row-info {
    display: flex;
    flex-grow: 1;
}

.row-data .pipeline-datastore-info, .row-data .pipeline-data-source-store-info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
}

.row-data .image-holder.datastore img {
    padding: 10px;
}

.row-data .image-holder img, 
.pipeline-row-data .image-holder img {
    flex-grow: 1;
    object-fit: contain;
}

.row-data .image-holder span, 
.pipeline-row-data .image-holder span {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 0.775rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
    margin-top: 10px;
}

.row-data .pipeline-datastore-info h3 {
    font-size: 1.3rem;
    color: #454f66;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    width: 100%;
}

.h3-container {
    width: 90%;
}

.h3-container  h3{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-data .pipeline-datastore-info .pipeline-datastore-name, 
.pipeline-row-data .pipeline-row-info .pipeline-row-name, 
.row-data .pipeline-data-source-store-info .pipeline-new-name {
    font-size: 2.5em;
    line-height: 42px;
    margin-bottom: 0;
    color: #454f66;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    width: 100%;
}


.row-labels {
	 display: flex;
     flex-wrap: wrap;
     grid-gap: 10px;
     gap: 10px;
	 align-items: center;
	 width: 100%;
}


.row-data .pipeline-date,
.pipeline-row-data .pipeline-date, 
.datastore-date{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    background-color: #f1f2f5;
    padding: 0 10px;
    border-radius: 5px;
    font-family: 'Nunito Sans', sans-serif;
}

.row-data .pipeline-date img,
.pipeline-row-data .pipeline-date img,
.datastore-date img {
    width: 10px;
    height: 10px;
}

.circle::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 2px;
}

.circle.orange::before {
    background-color: #ff7300;
}

.circle.grey::before {
    background-color: #808080;
}

.circle.green::before {
    background-color: #01aa4b;
}

.circle.red::before {
    background-color: #cc1323;
}

.circle.runningGreen::before {
    background: #01aa4b;
    height: 8px;
    width: 8px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.data-jobs-container {
    background-color: #fff;
    padding-bottom: 20px;
    border-top: 1px solid #ddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.job-actions {
    align-items: center;
}

.job-actions img {
    margin: 0 10px;
    width: 20px;
    height: 20px;
}

.delete-job {
    filter: invert(29%) sepia(7%) saturate(2359%) hue-rotate(184deg) brightness(91%) contrast(81%);
    cursor: pointer;
}

.pagination-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logs-button,
.pagination-buttons button,
.pagination-buttons button:focus,
.pagination-buttons button:active,
.pagination-buttons button:hover {
    display: inline-block;
    padding: 3px 20px;
    margin: 5px;
    border: 1px solid rgba(100, 100, 100, 0.3);
    font-family: 'Nunito Sans', sans-serif;
    border-radius: 5px;
    line-height: 22px;
    font-size: 16px;
    height: 30px;
    cursor: pointer;
    background-color: #fff;
    transition: 0.3s;
}

.logs-button:hover {
    background: #eee;
}

.pagination-buttons button:disabled {
    cursor: unset;
    color: #bbb;
}

.pagination-buttons button,
.pagination-buttons button:focus,
.pagination-buttons button:active,
.pagination-buttons button:hover {
    display: inline-flex;
    align-items: center;
}

.pagination-buttons button:first-child img {
    transform: rotate(180deg);
}

.pagination-buttons button:disabled img {
    filter: invert(83%) sepia(0%) saturate(0%) hue-rotate(141deg) brightness(92%) contrast(88%);
}

.pagination-buttons button img {
    width: 10px;
    height: 10px;
}

.pagination-buttons .arrow-right {
    margin-left: 5px;
}

.pagination-buttons .arrow-left {
    margin-right: 5px;
}

.button-wrapper {
    width: 42px;
    height: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
}

.button-wrapper.mobile {
    position: absolute;
    right: 1.2rem;
    bottom: 1.5rem;
}

.button-wrapper.disabled {
    cursor: not-allowed;
}

.button-wrapper:not(.disabled):hover,
.button-wrapper:not(.disabled):hover {
    background: #eee;
    border-radius: 50%;
}

.button-wrapper > * {
    filter: invert(29%) sepia(7%) saturate(2359%) hue-rotate(184deg) brightness(91%) contrast(81%);
    transition: 0.3s;
}

.button-wrapper img {
    width: 25px;
    height: 25px;
}

.button-wrapper i {
    font-size: 1.75em;
    line-height: 42px;
}

.button-wrapper .info-action.expanded {
    transform: rotate(90deg);
}

.button-wrapper.disabled > * {
	opacity: 0.5;
    pointer-events: none;
}

.collapse-center{
    padding-top: 0.2rem;
}

.expand-center{
    padding-left: 0.2rem;
}

.data-source-store{
    display: inline-flex;
    position: relative;
    padding: 10px 0 10px 0;
}

.pipeline-date-status, .datastore-date-status{
    padding-bottom: 0px;
    padding-top: 0px;
}

@media screen and (max-width: 767px){
    .pipeline-date-status, .datastore-date-status{
        flex-wrap: wrap;
        grid-gap: 5px;
        gap: 5px;
        flex-grow: 1;
    }
}

@media screen and (min-width: 746px) and (max-width: 850px){
    .pipeline-date-status, .datastore-date-status{
        margin-right: -2rem;
        max-width: 50%;
    }
}

.no-display{
    display: none;
}

.connect-button{
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(0.9);
}

.see-more-button{
    cursor: pointer; 
    position: absolute; 
    bottom: 0; 
    right: 0; 
    font-size: 13px;
    padding: 5px;
}

.see-more-button:hover{
    background-color: #eaedf5;
    border-radius: 7%;
}

div.source-description > p:nth-child(1){
    margin-top: 1rem;
    max-width: 80%;
    overflow: hidden;
    width: 75%;
    
}

div.source-description {
    width: 70%;
}


@media screen and (max-width: 631px){
    div.source-description {
        visibility: hidden;
    }
}

.see-more-description{
    height: 80px;
    overflow: hidden;
    padding-left: 3px;
    /* padding-top: 5px; */
    margin-top: -6.6rem !important;
    margin-left: 8.7rem !important;
    width: 100%;
    display: inline-flex;
    align-items: self-start;
}

.see-more-description::before{
    max-width: 75%;
    content: '';
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    position: absolute;
    z-index: 1;
    bottom: 15px;
    width: 65%;
    height:80px;
}

@media screen and (max-width: 631px){
    .see-more-description::before{
        display: none;
    }
}

.see-more-description::after{
    display: block;
    clear: both;
    content: "";
}

.see-more-link{
    cursor: pointer;
    position: relative;
    color: #4d4d4d !important;
    z-index: 2;
    font-size: 0.8rem;
    padding-top: 1rem;
}

.flex-wrap{
    flex-wrap: wrap;
}

.data-source-docs-info-action{
    position: relative;
    left: 9.85rem;
    top: -1.8rem;
}

.data-source-docs-info-action.expanded{
    position: relative;
    top: 1.25rem;
    left: 0%;
}

@media screen and (max-width: 631px){
    .data-source-docs-info-action{
        position: relative;
        float: center;
        top: -77px;
        font-size: 13px;
    }
}

@media screen and (max-width: 666px){
    .data-source-docs-info-action.expanded{
        position: relative;
        top: -0.5rem;
        float: left;
        bottom: 0rem;
    }
}

div.data-source-jobs-container.d-block{
    padding-top: 20px;
    padding-left: 13px;
    width: 60%;
    left: 9rem;
    top: -8.55em;
    border-top: 0;
    position: relative;
}

@media screen and (max-width: 666px){
    div.data-source-jobs-container.d-block{
        margin-top: -20px;
        width: 98%;
        left: 0rem;
        top: 0rem;
        border-top: none;
        position: relative;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 666pxz){
    .pipeline-data-source-store-info{
        display: none;
    }
}

.see-more-link-div{
    margin-top: -20px;
    margin-bottom: -5px;
    background-color: rgba(255, 255, 255, 0);
    padding-bottom: 0px;
    display: flex;
}
.data-job-row {
    position: relative;
    justify-content: space-between;
    padding: 10px 20px;
    text-wrap: nowrap;
}

.data-job-row::after {
    position: absolute;
    height: 1px;
    content: '';
    background: #d7dbe2;
    bottom: 0;
    left: 50%;
    width: 95%;
    margin-left: -47.5%;
}

.data-job-row:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.data-job-row:hover::after,
.data-job-row:nth-last-child(2)::after {
    content: none;
}

.data-job-row .info {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
}

.data-job-row .info > * {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    background-color: #f1f2f5;
    padding: 0 10px;
    border-radius: 5px;
    font-family: 'Nunito Sans', sans-serif;
}

.data-job-row .info img {
    width: 10px;
    height: 10px;
}

.chart-no-metadata {
    background-color: white;
    padding: 5px;
    word-break: break-word;
    color: red;
    white-space: pre-wrap;
    font-family: monospace;
}

.carousel {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}

.carousel .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    height: 250px;
}

.carousel .content>*,
*[role=dialog] .content>* {
    min-width: 0;
    max-width: 100%;
    max-height: 100%;
}

.carousel .content>*:hover {
    cursor: pointer;
}
.chartjs-chart {
    width: 100%;
    height: inherit;
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chartjs-tooltip {
    width: max-content;
    opacity: 0;
    position: absolute;
    font-weight: 600;
    background: white;
    box-shadow: 0 0 1px 1px #dddddd;
    border-radius: 3px;
    transition: all 0.1s ease;
    transform: translate(-50%, 0);
    pointer-events: auto !important;
    cursor: pointer;
    z-index: 1;
}

.chartjs-tooltip table {
    color: black;
}

.chartjs-tooltip span {
    color: #a8741a;
    font-size: 15px;
}

.chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.google-chart {
    height: 100%;
}

.html-table {
    font:0.75em/1.5 sans-serif;
    color:#333;
    background-color:#fff;
    padding:1em;
    overflow-x: auto;
}

.html-table table {
    width:100%;
    margin-bottom:1em;
    border-collapse: collapse;
}

.html-table th {
    font-weight:bold;
    background-color:#ddd;
}

.html-table th,td {
    padding:0.5em;
    border:1px solid #ccc;
}

.html-metric {
    font: 3em sans-serif;
    word-wrap: break-word;
    color:#333;
    padding:0.25em;
    overflow-x: auto;
}

.first-paragraph {
    font-size: 2em;
    display: block;
}

.other-paragraphs {
    display: inline-block;
    padding-right: 35px;
    padding-left: 35px;
    font-size: 1em;
}

.other-paragraphs:not(:last-of-type) {
    border-right: 2px solid #D3D3D3;
}
.data-page {
    background-color: #f1f2f7;
    min-height: 90vh;
    min-width: 800px;  /* poor responsive support, anything under 800px looks aweful */
    flex-direction: column;
    width: 100%;
}

/* the list container */
.listview {
    min-height: 90vh;
}

.data-search-container {
    margin: 15px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.data-search {
    position: relative;
    display: flex;
    flex: 1 1;
    align-items: center;
}

.sources-data-search{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}

/* when no data sources use the full height minus the toolbar, backbar, and datasource search bar */
.no-datasources {
    min-height: calc(100vh - var(--toolbar_height) - var(--backbar_height) - 74px);
}

.no-datasources h1 {
    padding: 20px;
    color: #a7a7a7;
}

.no-datasources img {
    padding: 0px 50px 50px 50px;
    width: 40%;
    max-width: 300px;
    filter: invert(69%) sepia(0%) saturate(0%) hue-rotate(188deg) brightness(100%) contrast(81%);
}

.data-search-clear {
    display: inline-block;
    position: absolute;
    left: 10px;
    font-size: 20px;
    color: rgb(142, 142, 142);
    cursor: pointer;
}

.data-search-clear img {
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    filter: invert(29%) sepia(7%) saturate(2359%) hue-rotate(184deg) brightness(91%) contrast(81%);
}

.data-search-bar {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    padding-left: 30px;
    align-items: center;
    outline: none;
    border: 0;
    border-radius: 5px;
    font-family: 'Nunito Sans', sans-serif;
}

.data-search-bar::-webkit-search-decoration,
.data-search-bar::-webkit-search-cancel-button,
.data-search-bar::-webkit-search-results-button,
.data-search-bar::-webkit-search-results-decoration {
    display: none;
}

.data-search-bar::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
.data-search-bar::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

.data-search-icon {
    position: absolute;
    right: 20px;
    width: 20px;
    cursor: pointer;
}

.btn-add-data {
    flex: 0.07 1;
    padding: 10px;
    background-color: #454f66;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
}

.btn-add-data .data-add-icon {
    width: 20px;
    filter: brightness(0) invert(1);
    margin-right: 10px;
}

.log-menu {
    position: fixed;
    width: 80%;
    padding: 10px;
    font-size: 18px;
    background-color: rgb(0, 0, 0);
    border-bottom: 1px solid #fff;
    text-align: end;
}

.log-data-container {
    margin-top: 40px;
}

.log-data-row {
    padding-left: 5px;
    display: block;
    white-space: nowrap;
    font-size: 15px;
    line-height: 16px;
}

.log-data-row .log-sequance {
    display: inline-block;
    margin-right: .5rem;
    width: 4ch;
    text-align: right;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: top;
}

.log-data-row .log-timestamp {
    display: inline-block;
    margin-right: .5rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 
    max-width: min-content;
}

.log-data-row .log-sequance::before,
.log-data-row .log-timestamp::before {
    content: attr(data-unselectable);
}

.log-data-row code {
    display: inline-block;
    color: #000;
    color: initial;
}

.my-modal-style {
    min-width: 90vw;
    min-height: 90vh;
}

.log-modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.log-modal-title .log-download-button {
    border: 1px solid rgba(100, 100, 100, 0.3);
    border-radius: 5px;
    padding: 3px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Nunito Sans', sans-serif;
    cursor: pointer;
    outline: none;
    transition: 0.3s;
}

.log-modal-title .log-download-button:hover {
    border: 1px solid #000;
    border-radius: 5px;
}

.log-modal-title .log-download-button img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

@media screen and (max-width: 767px) {
    .data-page .slick-slider.roundedListItemSlider .slick-arrow {
        display: none !important;
    }
    .data-page .slick-slider.roundedListItemSlider {
        padding: 0;
    }
}

@media screen and (max-width: 850px){
    .data-page {
        min-width: 0 !important;
    }
}

.data-sources{
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

#dataContainer{
    padding-bottom: 20px;
}
.editForms {
    background-color: #f1f2f7;
    min-height: calc(100vh - var(--toolbar_height));
}

.editForms nav ol {
    background: #fff;
    padding: 10px;
    border-bottom: 1px solid #D7DBE2;
}

@media screen and (max-width: 767px) {
    .rightside_pageContent .editForms {
        padding-left: 0px;
        padding-right: 0px;
    }
    .editForms {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media screen and (max-width: 950px) {
    .editForms-small-screen{
        min-height: calc(135vh - var(--toolbar_height));
    }
}

.editForms .form-heading {
    display: flex;
    justify-content: space-between;
}

.editForms .form-heading h1 {
    font-size: 2.5em;
    padding-top: 25px;
    padding-left: 15px;
}

.editForms .form-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}

.editForms .form-steps {
    display: flex;
    padding-top: 10px;
    align-items: center;
}

.editForms .form-steps .skip-btn {
    margin: 0 0 0 auto;
}

.editForms .form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.editForms .form-row * {
    padding-top: 0!important;
    padding-bottom: 0!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.editForms .form-row .help-button {
    top: 0;
    left: 0;
}

.editForms .form-buttons {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
    flex-direction:row-reverse;
}

.editForms .field {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    margin: 25px auto;
    width: 100%;
    padding: 0 5px 0 0;
}

.help {
    padding-right: 0px;
}

.google-login {
    margin-bottom: 8px;
}

.MuiTextField-root{
    margin-left: 0 !important;
}

.workspace-img-btn{
    width: 30px;
    height: 30px;
}

.dataPlugin-box {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    width: 120px !important;
    height: 120px;
    text-align: center;
    display: inline-flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    white-space: break-spaces;
    cursor: pointer;
    padding: 10px;
    position: relative;
}

.dataPlugin-box span {
    line-height: 16px;
    font-size: 12px;
}

.data-search, .sources-data-search {
    min-height: 1.5em !important;
    border: 1px solid #455066;
    border-radius: 5px;
}

.dataPlugin-search {
    display: inline-block;
    position: absolute;
    left: 5px;
    color: rgb(142, 142, 142);
    cursor: pointer;
    line-height: 0;
}

.searchImg {
    width: 20px;
    height: 20px;
    filter: invert(29%) sepia(7%) saturate(2359%) hue-rotate(184deg) brightness(91%) contrast(81%);
}

.searchImg-rotate {
    transform: rotate(45deg);
}

.supported-plugin {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 5px;
    top: 5px;
}

.actions-menu {
    width: 20px;
    height: 20px;
    position: absolute !important;
    right: 5px;
    top: 5px;
}

.selected-plugin {
    border: 1px solid #455066;
}

.plugin-img {
    max-width: 70px;
    max-height: 60px;    
    margin-bottom: 5px;
}

.properties-wrapper:nth-last-match(div) {
    background: orange;
}

.dataPlugin-properties {
    /* margin: 5px auto; */
    width: 100%;
    padding: 0 5px 0 0;
}

.step-header {
    display: flex;
    align-items: center;
    padding: 10px 3px;
}

.step-identicator {
    width: 25px;
    height: 25px;
    background-color: #424f74;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
}

.step-title {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.5;
    font-size: 15px;
}

.step-data-wrapper {
    padding-left: 15px;
    padding-bottom: 10px;
}

.step-data {
    border-left: 1px solid #424f74;
    padding-left: 20px;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.step-data .step-header {
    padding: unset
}

.clear-border {
    border: unset;
}

.expand-arrow {
    width: 15px;
    margin-right: 10px;
    transition: 0.4s;
}

.expand-arrow.expanded {
    transform: rotate(90deg);
}

.help-button {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 2px;
    top: 2px;
    transform: scale(0.85);
    border-radius: 50%;
}
  
.help-button-img{
    max-width: none !important;
    padding: 0 !important;
    filter: none !important;
}

  @keyframes glowing {
    0% {
      background-color: #454f66;
      box-shadow: 0 0 5px #454f66;
    }
    50% {
      background-color: #454f66;
      box-shadow: 0 0 20px #454f66;
    }
    100% {
      background-color: #454f66;
      box-shadow: 0 0 5px #454f66;
    }
  }

  .help-button:hover{
      animation: glowing 1500ms 0ms;
  }

  .help-info {
    width: 100%;
    padding: 0 20px;
  }
  
  div.MuiPaper-root.MuiAlert-root.MuiAlert-standardError.MuiPaper-elevation0{
      margin-bottom: 1rem;
  }

  .no-display{
      display: none;
  }

  .vl-help{
    border-left: 1px solid #ddd;
    height: 89%;
    position: absolute;
    top: 2.5rem;
    right: 0;
  }

.no-padding-right{
    padding-right: 0;
}

.no-padding-left{
    padding-left: 0;
} 

.description:not(.more)>*:first-child {
    margin: 0;
}

.description:not(.more)>*:not(:first-child) {
    display: none;
}

button.more {
    font-size: 0.8em;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: auto;
}

.actions {
    display: flex;
    align-items: center;
    height: var(--image-holder-small)
}

/* Help panel styles */
.help-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    height: calc(100% - 20px)
}

.help-big-screen {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    width: 100%;
    padding: 20px;
    height: 100%;
}

.help-big-screen-row{
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
    padding: 20px;
    height: calc(100% - 20px);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.help-container img {
    height: 150px;
}

.help-container a {
    word-wrap:break-word;
    word-break: break-all;
}

.help-title {
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.help-title img {
    height: 50px;
    padding: 10px;
}

.help-title h4 {
    margin: 0;
}

.close-help-panel{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 0.2rem;
}

.no-display{
    display: none;
}
/* use the full height minus the toolbar, backbar */
.signup {
    min-height: 90vh;
}

.signup h1 {
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
}

/* content when emailnotverified */
.emailnotverified {
    min-height: 90vh;
}

.emailnotverified h1 {
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
}

/* when no datasets use the full height minus the toolbar, 'topbar' tags, and display options at the top of the feed */
.no-datasets {
    min-height: 90vh;
}

.no-datasets h1 {
    padding: 20px;
    color: #a7a7a7;
}

.no-datasets p {
    color: #a7a7a7;
}

.no-datasets img {
    padding: 0px 50px 50px 50px;
    width: 40%;
    max-width: 300px;
    filter: invert(69%) sepia(0%) saturate(0%) hue-rotate(188deg) brightness(100%) contrast(81%);
}


/* content when no starred datasets */
.no-starred {
    min-height: 90vh;
}

.no-starred h1 {
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
}

.no-starred i {
    font-size: 15em;
    color: #a7a7a7;
    padding: 0px 50px 50px 50px;
}

#dataset-link:hover{
    cursor: pointer;
}
/* content when no news */
.no-news {
    min-height: 90vh;
}

.no-news h1 {
    text-align:center;
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
}

.no-news i {
    font-size: 15em;
    color: #a7a7a7;
    padding: 0px 50px 50px 50px;
}

.datastore-properties {
    margin: 0.2rem;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 10px 20px 0 20px;
}
.workspaceEdit .help-button {
    width: 30px;
    height: 30px;
    position: relative;
    transform: scale(0.85);
    border-radius: 50%;
    margin-left: auto;
}
  
.workspaceEdit .help-button img {
    max-width: none !important;
    filter: none !important;
}
@media screen and (max-width: 599.5px){
    div.MuiSnackbar-root.MuiSnackbar-anchorOriginTopCenter{
        left: 0 !important;
    }
}

.upload-img {
    width: 16rem;
    height: 16rem;
    border-radius: 40px;
    background: #FFFFFF;
    margin-bottom: 1rem;
}

.crop-image {
  margin-bottom: 20px;
}

.ReactCrop {
    position: relative;
    display: inline-block;
    cursor: crosshair;
    overflow: hidden;
    max-width: 100%;
}

.ReactCrop__crop-selection{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);
    box-sizing: border-box;
    cursor: move;
    box-shadow: 0 0 0 9999em rgb(0 0 0 / 50%);
    touch-action: manipulation;
    border: 1px solid;
    border-image-slice: 1;
    border-image-repeat: repeat;
}

.ReactCrop__image {
    display: block;
    max-width: 100%;
    touch-action: manipulation;
}

.workspace-form-image-container {
    position: relative;
    cursor: pointer;
}

.workspace-form-image {
    width: 100px;
    height: 100px;
    border: 1px solid;
    border-color: #455066;
    border-radius: 10%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

.workspace-form-image-container:hover .workspace-form-image {
  opacity: 0.3;
}

.workspace-form-image-container:hover .workspace-form-change-logo-button {
  opacity: 1;
}

.workspace-form-change-logo-button {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 70px;
  text-align: center;
}

.workspace-form-change-logo-text {
	display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-color: rgba(45, 50, 66, 0.8);
  color: white;
  font-size: 16px;
  bottom: 0;
  width: 100px;
  height: 36px;
  position: absolute;
}

.ReactCrop__image {
    min-width: 300px;
}
.onboarding-tasks {
    margin: 0 auto 0 auto;
    top: 4%;
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.remaining-tasks-label, .completed-tasks-label{
    position: relative;
    width: 130px;
    height: 22px;

    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: #454F66;
}

.setup-tasks {
    margin: 0 auto 0 auto;
    top: 13%;
}

.remaining-tasks {
    position: relative;
}

.completed-tasks{
    position: relative;
}

.task-card {
    position: relative;
    width: 100%;
    min-height: 100px;
    margin: 5px 10px 13px 0;
    background: #FFFFFF;
    border: 1px solid #D7DBE2;
    box-sizing: border-box;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}

.task-card > * {
    padding: 1em;
}

.task-card > *:last-child {
    padding: 1em;
}

.workspace-card{
    position: relative;
    width: 280px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #D7DBE2;
    box-sizing: border-box;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: 1rem 0;
}

.task-card-title {
	display: flex;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.task-title-label {
    margin-right: auto;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 22px;
    color: #454F66;
}

.tick-circle {
    width: 20px;
    height: 20px;
    z-index: 1;
    background: #F1F2F5;
    border-radius: 50%;
    border: 1px solid #454F66;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 12px;
}

.tick-circle i {
    opacity: 0.6;
}

.tick-circle img {
    height: 10px;
}

.tick-circle-running {
    background-image: conic-gradient(transparent 0deg, #454f66 270deg);
    animation: rotate 5s linear infinite;
}

.vl2{
    position: relative;
    width: 41px;
    height: 0px;
    left: -15%;
    top: 25%;
    border: 1px solid #D7DBE2;
    transform: rotate(90deg);
}

.vl {
    position: absolute;
    width: 126px;
    height: 0px;
    left: -93px;
    top: 120px;

    border: 1px solid #D7DBE2;
    transform: rotate(90deg);
}

.onboarding-title {
	padding-left: 20px;
}

.onboarding-title .onboarding-text {
    font-size: 1.3rem;
}

.onboarding-text, .onboarding-text-task{
    position: relative;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: inline;
    align-items: center;
    color: #454F66;
    margin: auto 0
}

.onboarding-text-task{
    display: block;
    word-break: break-word;
    padding: 0 1em;
    font-size: 0.875rem;
}

.taskcard-link-text {
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.tasks-buttons{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.tasks-buttons div:only-child{
    margin-left: auto !important;
}

.tasks-buttons button:only-child{
    margin-left: auto !important;
}

.onboarding-buttons {
    position: relative;
    width: 90px;
    height: 25px;
    background: #454F66;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    margin-right: 10px;
}

.onboarding-setup-tasks{
    /* min-height: 100vh; */
    display: block;
    width: 100%;
}

@media screen and (max-width: 755px) {
	.onboarding {
	    width: 90%;
	}
	.setup-tasks-mobile{
        width: 100%;
        overflow-x: hidden;
    }
    .onboarding-setup-tasks{
        height: 120vh;
    }
}

.tasks-icon{
    color: black !important;
    background-color: white !important;
}

.circle-border-tasks{    
    display: inline-flex;
    margin: 0px 15px;
}

.no-line-width {
    width: 0;
}

@media screen and (max-width: 720px){
    .task-card-mobile{
        width: 100%;
    }
    .long-text-div {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 45px;
    }
}

@media screen and (min-width: 756px) {
    .last-running-div{
        top: 110px;
    }
}
.onboarding {
    width: 100%;
}

.onboarding > * {
    margin: auto;
}

@media screen and (min-width: 767px) {
    .onboarding .form-heading {
        max-width: 70%;
    }
    .onboarding .form-container {
        max-width: 70%;
    }
    .onboarding .form-steps {
        max-width: 70%;
    }
}

/* full height minus the toolbar and 'topbar' tags container */
.onboarding-magic {
    min-height: 90vh;
    margin: 2rem auto 0 auto;
    width: 80%;
    text-align: center;
}

.onboarding-magic-icon{
    width: 260px;
    height: 260px;
    border-radius: 50%;
    position: absolute;
    top: -10rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: conic-gradient(transparent 225deg, #a7a7a7 270deg);
    opacity: 0.7;
    animation: rotate 5s linear infinite;
    z-index: 1;
}

.onboarding-magic-icon2 {
    width: 240px;
    height: 240px;
    padding: 10px;
    position: absolute;
    background: #f1f2f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 2;
}
.onboarding-magic-icon3{
    border-radius: 10px;
    z-index: 3;
}

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.onboarding-magic h1 {
    padding: 20px;
    color: #a7a7a7;
}

.onboarding-magic span {
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
    display: block;
    text-align: left;
}

.onboarding-magic i {
    position: absolute;
    top: auto;
    font-size: 15em;
    color: #a7a7a7;
    padding: 0px 50px 50px 50px;
}

.workspace-ready-icon {
    position: relative;
    margin: auto;
    align-items: center;
    justify-content: center;
    display: flex;
}
.step-circles{
    justify-content: flex-start;
    display: inline-flex;
}

.title-label {
	  margin: 0 !important;
    padding-left: 10px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 22px;
    color: #454F66;
}

.skip-btn {
    cursor: pointer;
}

@media screen and (min-width: 1199.5px) {
    .step-circles{
        max-width: 80%;
    }
}

.tick-circle-workspace{
    width: 20px;
    height: 20px;
    background: #F1F2F5;
    border-radius: 50%;
    border: 1px solid #454F66;
    box-sizing: border-box;
    margin: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 12px;
}

.onboarding-div {
    opacity: 0.5;
    background-color: #2A313F;
    width: 220vh;
    height: 174vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
}

.sub-step-number{
    position: absolute; 
    top: -5px; 
    left: -5px; 
    color: white; 
    background-color: #454F66;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    padding-top: 3px;
}

.guiding-div{
    position: absolute;
    width: 290px;
    height: 90px;
    right: -19rem;
    top: 0rem;
    z-index: 101;
    background: #FFFFFF;
    border: 1px solid #D7DBE2;
    box-sizing: border-box;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}

.step-three{
    right: -19.6rem; 
    top: 1rem;
}

.step-two{
    right: -19rem; 
    top: 0rem;
}

@media screen and (max-width: 767.5px) {
    .step-three{
        right: -0.5rem;
        top: -5rem;
    }
    .step-two{
        right: 0.5rem;
        top: -6.5rem;
    }
    .step-one{
        right: 0.5rem;
        top: -6.5rem;
    }
}

.close-guide{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.prev-next-btn{
    position: absolute;
    bottom: 5px;
    right: 5px;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
}

button:focus {
  outline: none;
}
