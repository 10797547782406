@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

.data-page {
    background-color: #f1f2f7;
    min-height: 90vh;
    min-width: 800px;  /* poor responsive support, anything under 800px looks aweful */
    flex-direction: column;
    width: 100%;
}

/* the list container */
.listview {
    min-height: 90vh;
}

.data-search-container {
    margin: 15px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.data-search {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
}

.sources-data-search{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}

/* when no data sources use the full height minus the toolbar, backbar, and datasource search bar */
.no-datasources {
    min-height: calc(100vh - var(--toolbar_height) - var(--backbar_height) - 74px);
}

.no-datasources h1 {
    padding: 20px;
    color: #a7a7a7;
}

.no-datasources img {
    padding: 0px 50px 50px 50px;
    width: 40%;
    max-width: 300px;
    filter: invert(69%) sepia(0%) saturate(0%) hue-rotate(188deg) brightness(100%) contrast(81%);
}

.data-search-clear {
    display: inline-block;
    position: absolute;
    left: 10px;
    font-size: 20px;
    color: rgb(142, 142, 142);
    cursor: pointer;
}

.data-search-clear img {
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    filter: invert(29%) sepia(7%) saturate(2359%) hue-rotate(184deg) brightness(91%) contrast(81%);
}

.data-search-bar {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    padding-left: 30px;
    align-items: center;
    outline: none;
    border: 0;
    border-radius: 5px;
    font-family: 'Nunito Sans', sans-serif;
}

.data-search-bar::-webkit-search-decoration,
.data-search-bar::-webkit-search-cancel-button,
.data-search-bar::-webkit-search-results-button,
.data-search-bar::-webkit-search-results-decoration {
    display: none;
}

.data-search-bar::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
.data-search-bar::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

.data-search-icon {
    position: absolute;
    right: 20px;
    width: 20px;
    cursor: pointer;
}

.btn-add-data {
    flex: 0.07;
    padding: 10px;
    background-color: #454f66;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
}

.btn-add-data .data-add-icon {
    width: 20px;
    filter: brightness(0) invert(1);
    margin-right: 10px;
}

.log-menu {
    position: fixed;
    width: 80%;
    padding: 10px;
    font-size: 18px;
    background-color: rgb(0, 0, 0);
    border-bottom: 1px solid #fff;
    text-align: end;
}

.log-data-container {
    margin-top: 40px;
}

.log-data-row {
    padding-left: 5px;
    display: block;
    white-space: nowrap;
    font-size: 15px;
    line-height: 16px;
}

.log-data-row .log-sequance {
    display: inline-block;
    margin-right: .5rem;
    width: 4ch;
    text-align: right;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: top;
}

.log-data-row .log-timestamp {
    display: inline-block;
    margin-right: .5rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
    max-width: min-content;
}

.log-data-row .log-sequance::before,
.log-data-row .log-timestamp::before {
    content: attr(data-unselectable);
}

.log-data-row code {
    display: inline-block;
    color: initial;
}

.my-modal-style {
    min-width: 90vw;
    min-height: 90vh;
}

.log-modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.log-modal-title .log-download-button {
    border: 1px solid rgba(100, 100, 100, 0.3);
    border-radius: 5px;
    padding: 3px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Nunito Sans', sans-serif;
    cursor: pointer;
    outline: none;
    transition: 0.3s;
}

.log-modal-title .log-download-button:hover {
    border: 1px solid #000;
    border-radius: 5px;
}

.log-modal-title .log-download-button img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

@media screen and (max-width: 767px) {
    .data-page .slick-slider.roundedListItemSlider .slick-arrow {
        display: none !important;
    }
    .data-page .slick-slider.roundedListItemSlider {
        padding: 0;
    }
}

@media screen and (max-width: 850px){
    .data-page {
        min-width: 0 !important;
    }
}

.data-sources{
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

#dataContainer{
    padding-bottom: 20px;
}