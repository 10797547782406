.onboarding-tasks {
    margin: 0 auto 0 auto;
    top: 4%;
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.remaining-tasks-label, .completed-tasks-label{
    position: relative;
    width: 130px;
    height: 22px;

    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: #454F66;
}

.setup-tasks {
    margin: 0 auto 0 auto;
    top: 13%;
}

.remaining-tasks {
    position: relative;
}

.completed-tasks{
    position: relative;
}

.task-card {
    position: relative;
    width: 100%;
    min-height: 100px;
    margin: 5px 10px 13px 0;
    background: #FFFFFF;
    border: 1px solid #D7DBE2;
    box-sizing: border-box;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}

.task-card > * {
    padding: 1em;
}

.task-card > *:last-child {
    padding: 1em;
}

.workspace-card{
    position: relative;
    width: 280px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #D7DBE2;
    box-sizing: border-box;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: 1rem 0;
}

.task-card-title {
	display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.task-title-label {
    margin-right: auto;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 22px;
    color: #454F66;
}

.tick-circle {
    width: 20px;
    height: 20px;
    z-index: 1;
    background: #F1F2F5;
    border-radius: 50%;
    border: 1px solid #454F66;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 12px;
}

.tick-circle i {
    opacity: 0.6;
}

.tick-circle img {
    height: 10px;
}

.tick-circle-running {
    background-image: conic-gradient(transparent 0deg, #454f66 270deg);
    animation: rotate 5s linear infinite;
}

.vl2{
    position: relative;
    width: 41px;
    height: 0px;
    left: -15%;
    top: 25%;
    border: 1px solid #D7DBE2;
    transform: rotate(90deg);
}

.vl {
    position: absolute;
    width: 126px;
    height: 0px;
    left: -93px;
    top: 120px;

    border: 1px solid #D7DBE2;
    transform: rotate(90deg);
}

.onboarding-title {
	padding-left: 20px;
}

.onboarding-title .onboarding-text {
    font-size: 1.3rem;
}

.onboarding-text, .onboarding-text-task{
    position: relative;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: inline;
    align-items: center;
    color: #454F66;
    margin: auto 0
}

.onboarding-text-task{
    display: block;
    word-break: break-word;
    padding: 0 1em;
    font-size: 0.875rem;
}

.taskcard-link-text {
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.tasks-buttons{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.tasks-buttons div:only-child{
    margin-left: auto !important;
}

.tasks-buttons button:only-child{
    margin-left: auto !important;
}

.onboarding-buttons {
    position: relative;
    width: 90px;
    height: 25px;
    background: #454F66;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    margin-right: 10px;
}

.onboarding-setup-tasks{
    /* min-height: 100vh; */
    display: block;
    width: 100%;
}

@media screen and (max-width: 755px) {
	.onboarding {
	    width: 90%;
	}
	.setup-tasks-mobile{
        width: 100%;
        overflow-x: hidden;
    }
    .onboarding-setup-tasks{
        height: 120vh;
    }
}

.tasks-icon{
    color: black !important;
    background-color: white !important;
}

.circle-border-tasks{    
    display: inline-flex;
    margin: 0px 15px;
}

.no-line-width {
    width: 0;
}

@media screen and (max-width: 720px){
    .task-card-mobile{
        width: 100%;
    }
    .long-text-div {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 45px;
    }
}

@media screen and (min-width: 756px) {
    .last-running-div{
        top: 110px;
    }
}