:root {
    --primary-color: #191919;
    --secondary-color: #2a303e;
    --sidemenu_width: 80px;
    --toolbar_height: 54px;
    --backbar_height: 45px;
    --border_color_grey: #dbdbdb;
    --help-big-screen: calc(100% - 20px);
    --image-holder: 120px;
    --image-holder-small: 80px;
}

@media screen and (max-width: 767px) {
  :root {
      --toolbar_height: 60px;
  }
}