.chart-no-metadata {
    background-color: white;
    padding: 5px;
    word-break: break-word;
    color: red;
    white-space: pre-wrap;
    font-family: monospace;
}

.carousel {
    display: flex;
    align-items: center;
    gap: 12px;
}

.carousel .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    height: 250px;
}

.carousel .content>*,
*[role=dialog] .content>* {
    min-width: 0;
    max-width: 100%;
    max-height: 100%;
}

.carousel .content>*:hover {
    cursor: pointer;
}