@import '../variables.css';

.profile_dropdown .dropdown-menu.dropdown-menu-right {
    transition: all 0.5s ease-in-out;
    display: block;
    right: -360px;
}
.profile_dropdown.show .dropdown-menu.show.dropdown-menu-right {
    right: 0;
}
.profile_dropdown .dropdown-menu {
    padding: 0;
    width: 330px;
    border-radius: 0;
    height: calc(100vh - 54px);
    border: none;
    top: var(--toolbar_height);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
}
.profile_dropdown .profile_dropdown_head {
    padding: 18px 25px;
}
.profile_dropdown .profile_image {
    height: 45px;
    width: 45px;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 12px;
}
.profile_dropdown .profile_name {
    font-size: 17px;
    line-height: 17px;
    color: var(--primary-color);
}
.profile_dropdown .edit_profile {
    font-size: 15px;
    line-height: 17px;
    color: #a7a7a7;
    margin-top: 5px;
}
.profile_dropdown .dropdown-item img {
    height: 15px;
    width: 20px;
    margin-left: 3px;
    margin-right: 7px;
}
.profile_dropdown .dropdown-item.profile_name,
.profile_dropdown .dropdown-item.edit_profile {
    display: inline;
    padding: 3px;
}
.profile_dropdown .edit_profile:hover,
.profile_dropdown .edit_profile:focus {
    color: #a7a7a7;
}
.profile_dropdown a {
    color: var(--primary-color);
    font-size: 16px;
    line-height: 22px;
}
.profile_dropdown .dropdown-item:hover,
.profile_dropdown .dropdown-item:focus,
.profile_dropdown .dropdown-item.active {
    background: transparent;
    color: var(--primary-color);
    text-decoration: none;
}

.profile_dropdown .dropdown-item svg {
    color: #a7a7a7;
    fill: #a7a7a7;
    width: 20px;
    margin-right: 15px;
}
.profile_dropdown .dropdown-item:hover,
.profile_dropdown .dropdown-item.active {
    font-weight: 500;
}
.profile_dropdown .dropdown-item:hover svg,
.profile_dropdown .dropdown-item.active svg {
    fill: var(--primary-color);
}
.profile_dropdown .dropdown-item {
    padding: 13px 25px;
    display: flex;
    align-items: center;
    white-space: unset;
}

.profile_dropdown .workspace_dropdown span {
    color: var(--primary-color);
}

.profile_dropdown .workspace_dropdown .dropdown-menu {
    margin: 5px 0;
    padding-left: 59px;
    padding-bottom: 15px;
    /* border-radius: 0; */
    width: 100%;
    border-width: 0 0;
    position: relative;
    height: auto;
    box-shadow: none;
    top: 0;
}
.profile_dropdown .workspace_dropdown img {
    height: 13px;
    width: 7.7px;
    float: right;
    right: 25px;
}
.profile_dropdown .workspace_dropdown.show img {
    transform: rotate(-90deg);
}
.profile_dropdown .workspace_dropdown .dropdown-menu .dropdown-item {
    padding: 0;
    font-size: 15px;
    line-height: 30px;
}

.collapse:not(.show) h1 {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.collapse:not(.show) {
    overflow: hidden;
    text-overflow: ellipsis;
}
