.editForms {
    background-color: #f1f2f7;
    min-height: calc(100vh - var(--toolbar_height));
}

.editForms nav ol {
    background: #fff;
    padding: 10px;
    border-bottom: 1px solid #D7DBE2;
}

@media screen and (max-width: 767px) {
    .rightside_pageContent .editForms {
        padding-left: 0px;
        padding-right: 0px;
    }
    .editForms {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media screen and (max-width: 950px) {
    .editForms-small-screen{
        min-height: calc(135vh - var(--toolbar_height));
    }
}

.editForms .form-heading {
    display: flex;
    justify-content: space-between;
}

.editForms .form-heading h1 {
    font-size: 2.5em;
    padding-top: 25px;
    padding-left: 15px;
}

.editForms .form-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}

.editForms .form-steps {
    display: flex;
    padding-top: 10px;
    align-items: center;
}

.editForms .form-steps .skip-btn {
    margin: 0 0 0 auto;
}

.editForms .form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.editForms .form-row * {
    padding-top: 0!important;
    padding-bottom: 0!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.editForms .form-row .help-button {
    top: 0;
    left: 0;
}

.editForms .form-buttons {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction:row-reverse;
}

.editForms .field {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    margin: 25px auto;
    width: 100%;
    padding: 0 5px 0 0;
}

.help {
    padding-right: 0px;
}

.google-login {
    margin-bottom: 8px;
}

.MuiTextField-root{
    margin-left: 0 !important;
}

.workspace-img-btn{
    width: 30px;
    height: 30px;
}
