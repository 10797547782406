.chartjs-chart {
    width: 100%;
    height: inherit;
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chartjs-tooltip {
    width: max-content;
    opacity: 0;
    position: absolute;
    font-weight: 600;
    background: white;
    box-shadow: 0 0 1px 1px #dddddd;
    border-radius: 3px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    pointer-events: auto !important;
    cursor: pointer;
    z-index: 1;
}

.chartjs-tooltip table {
    color: black;
}

.chartjs-tooltip span {
    color: #a8741a;
    font-size: 15px;
}

.chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}
