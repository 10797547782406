.onboarding {
    width: 100%;
}

.onboarding > * {
    margin: auto;
}

@media screen and (min-width: 767px) {
    .onboarding .form-heading {
        max-width: 70%;
    }
    .onboarding .form-container {
        max-width: 70%;
    }
    .onboarding .form-steps {
        max-width: 70%;
    }
}

/* full height minus the toolbar and 'topbar' tags container */
.onboarding-magic {
    min-height: 90vh;
    margin: 2rem auto 0 auto;
    width: 80%;
    text-align: center;
}

.onboarding-magic-icon{
    width: 260px;
    height: 260px;
    border-radius: 50%;
    position: absolute;
    top: -10rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: conic-gradient(transparent 225deg, #a7a7a7 270deg);
    opacity: 0.7;
    animation: rotate 5s linear infinite;
    z-index: 1;
}

.onboarding-magic-icon2 {
    width: 240px;
    height: 240px;
    padding: 10px;
    position: absolute;
    background: #f1f2f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 2;
}
.onboarding-magic-icon3{
    border-radius: 10px;
    z-index: 3;
}

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.onboarding-magic h1 {
    padding: 20px;
    color: #a7a7a7;
}

.onboarding-magic span {
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
    display: block;
    text-align: left;
}

.onboarding-magic i {
    position: absolute;
    top: auto;
    font-size: 15em;
    color: #a7a7a7;
    padding: 0px 50px 50px 50px;
}

.workspace-ready-icon {
    position: relative;
    margin: auto;
    align-items: center;
    justify-content: center;
    display: flex;
}
.step-circles{
    justify-content: flex-start;
    display: inline-flex;
}

.title-label {
	  margin: 0 !important;
    padding-left: 10px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 22px;
    color: #454F66;
}

.skip-btn {
    cursor: pointer;
}

@media screen and (min-width: 1199.5px) {
    .step-circles{
        max-width: 80%;
    }
}

.tick-circle-workspace{
    width: 20px;
    height: 20px;
    background: #F1F2F5;
    border-radius: 50%;
    border: 1px solid #454F66;
    box-sizing: border-box;
    margin: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 12px;
}

.onboarding-div {
    opacity: 0.5;
    background-color: #2A313F;
    width: 220vh;
    height: 174vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
}

.sub-step-number{
    position: absolute; 
    top: -5px; 
    left: -5px; 
    color: white; 
    background-color: #454F66;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    padding-top: 3px;
}

.guiding-div{
    position: absolute;
    width: 290px;
    height: 90px;
    right: -19rem;
    top: 0rem;
    z-index: 101;
    background: #FFFFFF;
    border: 1px solid #D7DBE2;
    box-sizing: border-box;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}

.step-three{
    right: -19.6rem; 
    top: 1rem;
}

.step-two{
    right: -19rem; 
    top: 0rem;
}

@media screen and (max-width: 767.5px) {
    .step-three{
        right: -0.5rem;
        top: -5rem;
    }
    .step-two{
        right: 0.5rem;
        top: -6.5rem;
    }
    .step-one{
        right: 0.5rem;
        top: -6.5rem;
    }
}

.close-guide{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.prev-next-btn{
    position: absolute;
    bottom: 5px;
    right: 5px;
}
