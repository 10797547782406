.spinner {
    position: absolute !important;
    z-index: 0 !important;
}

.spinner~* {
    height: 100%;
    min-height: inherit;
}

.spinner.active~* {
    visibility: hidden;
}