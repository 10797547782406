.html-table {
    font:0.75em/1.5 sans-serif;
    color:#333;
    background-color:#fff;
    padding:1em;
    overflow-x: auto;
}

.html-table table {
    width:100%;
    margin-bottom:1em;
    border-collapse: collapse;
}

.html-table th {
    font-weight:bold;
    background-color:#ddd;
}

.html-table th,td {
    padding:0.5em;
    border:1px solid #ccc;
}
