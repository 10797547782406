.description:not(.more)>*:first-child {
    margin: 0;
}

.description:not(.more)>*:not(:first-child) {
    display: none;
}

button.more {
    font-size: 0.8em;
    width: fit-content;
    margin-top: auto;
}

.actions {
    display: flex;
    align-items: center;
    height: var(--image-holder-small)
}
