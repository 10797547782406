.apikey-list-row {
    padding: 10px;
    border-bottom: 1px solid #d0d0d0;
}

.pointer .MuiInputBase-root, .pointer .MuiInputBase-input {
    cursor: pointer;
}

.MuiButton-contained {
	max-height: 40px;
}