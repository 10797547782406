.vertical-center {
    display: flex;
    align-items: center;
}

.inline-vertical-center {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.row-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dataRow-container {
    display: flex;
    margin: auto auto 20px;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.metrics-chart {
    height: 20vh;
}

.row-info-container , .source-search{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    align-items: flex-start;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.source-search{
    height: auto;
    padding: 15px !important;
}

.collapse-pipeline-datastore{
    border-radius: 10px !important;
}

.row-info-container .row-data {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.row-info-container .pipeline-row-data {
    width: 100%;
    position: relative;
}

.image-holders {
    max-width: 100%;
    overflow: auto;
    gap: 10px;
}

.row-data .image-holder,
.pipeline-row-data .image-holder {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: var(--image-holder);
    height: var(--image-holder);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.image-holder.overlay,
.image-holder .overlay,
.image-holder :not(.overlay) {
    transition: 0.2s ease;
}

.image-holder .overlay {
    position: absolute;
    background-color: var(--secondary-color);
    color: white;
    padding: 0.25em 0;
    opacity: 0;
}

.image-holder.overlay:hover {
    border-color: rgba(45, 50, 66, 0.8);
}

.image-holder.overlay:hover > :not(.overlay) {
    opacity: 0.1;
}

.image-holder.overlay:hover > .overlay {
    opacity: 0.8;
}

.image-holder-small {
    width: var(--image-holder-small) !important;
    height: var(--image-holder-small) !important;
}

.image-holder > * {
    min-height: 0;
    width: inherit;
    max-width: 100%
}


.pipeline-row-data .pipeline-row-info {
    display: flex;
    flex-grow: 1;
}

.row-data .pipeline-datastore-info, .row-data .pipeline-data-source-store-info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
}

.row-data .image-holder.datastore img {
    padding: 10px;
}

.row-data .image-holder img, 
.pipeline-row-data .image-holder img {
    flex-grow: 1;
    object-fit: contain;
}

.row-data .image-holder span, 
.pipeline-row-data .image-holder span {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 0.775rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
    margin-top: 10px;
}

.row-data .pipeline-datastore-info h3 {
    font-size: 1.3rem;
    color: #454f66;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    width: 100%;
}

.h3-container {
    width: 90%;
}

.h3-container  h3{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-data .pipeline-datastore-info .pipeline-datastore-name, 
.pipeline-row-data .pipeline-row-info .pipeline-row-name, 
.row-data .pipeline-data-source-store-info .pipeline-new-name {
    font-size: 2.5em;
    line-height: 42px;
    margin-bottom: 0;
    color: #454f66;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    width: 100%;
}


.row-labels {
	 display: flex;
     flex-wrap: wrap;
     gap: 10px;
	 align-items: center;
	 width: 100%;
}


.row-data .pipeline-date,
.pipeline-row-data .pipeline-date, 
.datastore-date{
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #f1f2f5;
    padding: 0 10px;
    border-radius: 5px;
    font-family: 'Nunito Sans', sans-serif;
}

.row-data .pipeline-date img,
.pipeline-row-data .pipeline-date img,
.datastore-date img {
    width: 10px;
    height: 10px;
}

.circle::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 2px;
}

.circle.orange::before {
    background-color: #ff7300;
}

.circle.grey::before {
    background-color: #808080;
}

.circle.green::before {
    background-color: #01aa4b;
}

.circle.red::before {
    background-color: #cc1323;
}

.circle.runningGreen::before {
    background: #01aa4b;
    height: 8px;
    width: 8px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.data-jobs-container {
    background-color: #fff;
    padding-bottom: 20px;
    border-top: 1px solid #ddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.job-actions {
    align-items: center;
}

.job-actions img {
    margin: 0 10px;
    width: 20px;
    height: 20px;
}

.delete-job {
    filter: invert(29%) sepia(7%) saturate(2359%) hue-rotate(184deg) brightness(91%) contrast(81%);
    cursor: pointer;
}

.pagination-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logs-button,
.pagination-buttons button,
.pagination-buttons button:focus,
.pagination-buttons button:active,
.pagination-buttons button:hover {
    display: inline-block;
    padding: 3px 20px;
    margin: 5px;
    border: 1px solid rgba(100, 100, 100, 0.3);
    font-family: 'Nunito Sans', sans-serif;
    border-radius: 5px;
    line-height: 22px;
    font-size: 16px;
    height: 30px;
    cursor: pointer;
    background-color: #fff;
    transition: 0.3s;
}

.logs-button:hover {
    background: #eee;
}

.pagination-buttons button:disabled {
    cursor: unset;
    color: #bbb;
}

.pagination-buttons button,
.pagination-buttons button:focus,
.pagination-buttons button:active,
.pagination-buttons button:hover {
    display: inline-flex;
    align-items: center;
}

.pagination-buttons button:first-child img {
    transform: rotate(180deg);
}

.pagination-buttons button:disabled img {
    filter: invert(83%) sepia(0%) saturate(0%) hue-rotate(141deg) brightness(92%) contrast(88%);
}

.pagination-buttons button img {
    width: 10px;
    height: 10px;
}

.pagination-buttons .arrow-right {
    margin-left: 5px;
}

.pagination-buttons .arrow-left {
    margin-right: 5px;
}

.button-wrapper {
    width: 42px;
    height: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
}

.button-wrapper.mobile {
    position: absolute;
    right: 1.2rem;
    bottom: 1.5rem;
}

.button-wrapper.disabled {
    cursor: not-allowed;
}

.button-wrapper:not(.disabled):hover,
.button-wrapper:not(.disabled):hover {
    background: #eee;
    border-radius: 50%;
}

.button-wrapper > * {
    filter: invert(29%) sepia(7%) saturate(2359%) hue-rotate(184deg) brightness(91%) contrast(81%);
    transition: 0.3s;
}

.button-wrapper img {
    width: 25px;
    height: 25px;
}

.button-wrapper i {
    font-size: 1.75em;
    line-height: 42px;
}

.button-wrapper .info-action.expanded {
    transform: rotate(90deg);
}

.button-wrapper.disabled > * {
	opacity: 0.5;
    pointer-events: none;
}

.collapse-center{
    padding-top: 0.2rem;
}

.expand-center{
    padding-left: 0.2rem;
}

.data-source-store{
    display: inline-flex;
    position: relative;
    padding: 10px 0 10px 0;
}

.pipeline-date-status, .datastore-date-status{
    padding-bottom: 0px;
    padding-top: 0px;
}

@media screen and (max-width: 767px){
    .pipeline-date-status, .datastore-date-status{
        flex-wrap: wrap;
        gap: 5px;
        flex-grow: 1;
    }
}

@media screen and (min-width: 746px) and (max-width: 850px){
    .pipeline-date-status, .datastore-date-status{
        margin-right: -2rem;
        max-width: 50%;
    }
}

.no-display{
    display: none;
}

.connect-button{
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(0.9);
}

.see-more-button{
    cursor: pointer; 
    position: absolute; 
    bottom: 0; 
    right: 0; 
    font-size: 13px;
    padding: 5px;
}

.see-more-button:hover{
    background-color: #eaedf5;
    border-radius: 7%;
}

div.source-description > p:nth-child(1){
    margin-top: 1rem;
    max-width: 80%;
    overflow: hidden;
    width: 75%;
    
}

div.source-description {
    width: 70%;
}


@media screen and (max-width: 631px){
    div.source-description {
        visibility: hidden;
    }
}

.see-more-description{
    height: 80px;
    overflow: hidden;
    padding-left: 3px;
    /* padding-top: 5px; */
    margin-top: -6.6rem !important;
    margin-left: 8.7rem !important;
    width: 100%;
    display: inline-flex;
    align-items: self-start;
}

.see-more-description::before{
    max-width: 75%;
    content: '';
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    position: absolute;
    z-index: 1;
    bottom: 15px;
    width: 65%;
    height:80px;
}

@media screen and (max-width: 631px){
    .see-more-description::before{
        display: none;
    }
}

.see-more-description::after{
    display: block;
    clear: both;
    content: "";
}

.see-more-link{
    cursor: pointer;
    position: relative;
    color: #4d4d4d !important;
    z-index: 2;
    font-size: 0.8rem;
    padding-top: 1rem;
}

.flex-wrap{
    flex-wrap: wrap;
}

.data-source-docs-info-action{
    position: relative;
    left: 9.85rem;
    top: -1.8rem;
}

.data-source-docs-info-action.expanded{
    position: relative;
    top: 1.25rem;
    left: 0%;
}

@media screen and (max-width: 631px){
    .data-source-docs-info-action{
        position: relative;
        float: center;
        top: -77px;
        font-size: 13px;
    }
}

@media screen and (max-width: 666px){
    .data-source-docs-info-action.expanded{
        position: relative;
        top: -0.5rem;
        float: left;
        bottom: 0rem;
    }
}

div.data-source-jobs-container.d-block{
    padding-top: 20px;
    padding-left: 13px;
    width: 60%;
    left: 9rem;
    top: -8.55em;
    border-top: 0;
    position: relative;
}

@media screen and (max-width: 666px){
    div.data-source-jobs-container.d-block{
        margin-top: -20px;
        width: 98%;
        left: 0rem;
        top: 0rem;
        border-top: none;
        position: relative;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 666pxz){
    .pipeline-data-source-store-info{
        display: none;
    }
}

.see-more-link-div{
    margin-top: -20px;
    margin-bottom: -5px;
    background-color: rgba(255, 255, 255, 0);
    padding-bottom: 0px;
    display: flex;
}