.workbench {
    margin: 0px !important;
    padding: var(--toolbar_height) 0px 0px 0px;
    min-height: 100vh;
}

.workbench-container {
    padding: 0px !important;
}

.app-action {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 100px!important;
    width: 100px!important;
    margin: 30px;
    background-color: #f08f3f;
    border-radius: 50%;
}

.app-action:hover {
    background: #a7a7a7 !important;
}

/* fix padding under Toolbar after resize */
@media screen and (max-width: 767px) {
    .app-action {
        bottom: var(--toolbar_height);
    }
    .removeHeader .workbench {
        padding-top: 0!important;
    }
}
/* @media screen and (min-width:768px) and (max-width: 991px) {
  .workbench { padding-top: 68px; }
}
@media screen and (min-width: 992px) {
  .workbench { padding-top: 68px; }
} */

.hasSearchForm.collapse:not(.show) {
    display: none;
}

/*#########################################################################
#########################################################################*/

@media screen and (min-width: 768px) {
    .toggleTopOnScroll .stickyTop {
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        /* left: var(--sidemenu_width); */
        /* width: 100%; */
        background: #fff;
    }
    /* .toggleTopOnScroll .toolbar.navbar.fixed-top.hide {
        top: -100px;
    }
    .toggleTopOnScroll .toolbar.navbar.fixed-top.show {
        top: 0;
    } */

    .toggleTopOnScroll .hasSearchForm.show.hide-sticky,
    .toggleTopOnScroll .hasSearchForm.hide-sticky,
    /* .toggleTopOnScroll .toolbar.navbar.fixed-top.hide, */
    .toggleTopOnScroll .stickyTop.hide {
        opacity: 0;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }

    .toggleTopOnScroll .hasSearchForm.show.show-sticky,
    .toggleTopOnScroll .hasSearchForm.show-sticky,
    /* .toggleTopOnScroll .toolbar.navbar.fixed-top.show, */
    .toggleTopOnScroll .stickyTop.show {
        opacity: 1;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }

    .toggleTopOnScroll .stickyTop.hide {
        top: -100px;
    }
    .toggleTopOnScroll .stickyTop.show {
        top: var(--toolbar_height);
    }
    .toggleTopOnScroll .rightside_pageContent.hasStickyTags {
        padding-top: 80px;
    }
}

/*#########################################################################
#########################################################################*/

@media screen and (max-width: 767px) {
    .toggleTopOnScroll .stickyTop,
    .toggleTopOnScroll .toolbar.navbar.fixed-top {
        -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
        animation: 1.5s ease 0s normal forwards 1 fadein;
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        40% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadein {
        0% {
            opacity: 0;
        }
        40% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .toggleTopOnScroll .toolbar.navbar.fixed-top,
    .stickyBack {
        top: 0;
    }

    .toggleTopOnScroll .stickyTop {
        top: 60px;
    }
    .toggleTopOnScroll .stickyTop,
    .stickyBack {
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        width: 100%;
        background: #fff;
    }
    .toggleTopOnScroll .toolbar.navbar.fixed-top.hide,
    .stickyBack.hide {
        top: -100px;
    }
    .toggleTopOnScroll .toolbar.navbar.fixed-top.show,
    .stickyBack.show {
        top: 0;
    }

    .toggleTopOnScroll .hasSearchForm.show.hide-sticky,
    .toggleTopOnScroll .hasSearchForm.hide-sticky,
    .toggleTopOnScroll .toolbar.navbar.fixed-top.hide,
    .toggleTopOnScroll .stickyTop.hide,
    .stickyBack.hide {
        opacity: 0;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }

    .toggleTopOnScroll .hasSearchForm.show.show-sticky,
    .toggleTopOnScroll .hasSearchForm.show-sticky,
    .toggleTopOnScroll .toolbar.navbar.fixed-top.show,
    .toggleTopOnScroll .stickyTop.show,
    .stickyBack.show {
        opacity: 1;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }

    .toggleTopOnScroll .stickyTop.hide {
        top: -100px !important;
    }
    .toggleTopOnScroll .stickyTop,
    .toggleTopOnScroll .stickyTop.show {
        top: 60px;
    }
    .toggleTopOnScroll .rightside_pageContent.hasStickyTags {
        padding-top: 80px;
    }
    /*END sticky content*/
    /* ----------------------- */
}
