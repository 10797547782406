/* when no datasets use the full height minus the toolbar, 'topbar' tags, and display options at the top of the feed */
.no-datasets {
    min-height: 90vh;
}

.no-datasets h1 {
    padding: 20px;
    color: #a7a7a7;
}

.no-datasets p {
    color: #a7a7a7;
}

.no-datasets img {
    padding: 0px 50px 50px 50px;
    width: 40%;
    max-width: 300px;
    filter: invert(69%) sepia(0%) saturate(0%) hue-rotate(188deg) brightness(100%) contrast(81%);
}

