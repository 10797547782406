/* content when emailnotverified */
.emailnotverified {
    min-height: 90vh;
}

.emailnotverified h1 {
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
}
