/* primaryColor="#d9d9d9"
  secondaryColor="#ecebeb" */

.dataLoader_parent .boxload rect {
    background-color: #d9d9d9;
}

.dataLoader_parent .circleSliderPlaceholder {
    width: 100%;
}

.dataLoader_parent .circleSliderPlaceholder circle {
    fill: #d9d9d9;
}

/* .dataLoader_parent .circleSliderPlaceholder rect {
  fill: #ecebeb;
} */

.dataLoader_parent .placeholderDivider rect {
    width: 100%;
    fill: #d9d9d9;
}

.dataLoader_parent .placeholderList {
    width: 100%;
}

.dataLoader_parent .hideOverflow {
    overflow: hidden;
}
