.workspaceEdit .help-button {
    width: 30px;
    height: 30px;
    position: relative;
    transform: scale(0.85);
    border-radius: 50%;
    margin-left: auto;
}
  
.workspaceEdit .help-button img {
    max-width: none !important;
    filter: none !important;
}
@media screen and (max-width: 599.5px){
    div.MuiSnackbar-root.MuiSnackbar-anchorOriginTopCenter{
        left: 0 !important;
    }
}

.upload-img {
    width: 16rem;
    height: 16rem;
    border-radius: 40px;
    background: #FFFFFF;
    margin-bottom: 1rem;
}

.crop-image {
  margin-bottom: 20px;
}

.ReactCrop {
    position: relative;
    display: inline-block;
    cursor: crosshair;
    overflow: hidden;
    max-width: 100%;
}

.ReactCrop__crop-selection{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);
    box-sizing: border-box;
    cursor: move;
    box-shadow: 0 0 0 9999em rgb(0 0 0 / 50%);
    touch-action: manipulation;
    border: 1px solid;
    border-image-slice: 1;
    border-image-repeat: repeat;
}

.ReactCrop__image {
    display: block;
    max-width: 100%;
    touch-action: manipulation;
}

.workspace-form-image-container {
    position: relative;
    cursor: pointer;
}

.workspace-form-image {
    width: 100px;
    height: 100px;
    border: 1px solid;
    border-color: #455066;
    border-radius: 10%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

.workspace-form-image-container:hover .workspace-form-image {
  opacity: 0.3;
}

.workspace-form-image-container:hover .workspace-form-change-logo-button {
  opacity: 1;
}

.workspace-form-change-logo-button {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 70px;
  text-align: center;
}

.workspace-form-change-logo-text {
	display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-color: rgba(45, 50, 66, 0.8);
  color: white;
  font-size: 16px;
  bottom: 0;
  width: 100px;
  height: 36px;
  position: absolute;
}

.ReactCrop__image {
    min-width: 300px;
}