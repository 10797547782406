/* content when no news */
.no-news {
    min-height: 90vh;
}

.no-news h1 {
    text-align:center;
    padding: 20px;
    color: #a7a7a7;
    margin-bottom: 100px;
}

.no-news i {
    font-size: 15em;
    color: #a7a7a7;
    padding: 0px 50px 50px 50px;
}
