.dataPlugin-box {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    width: 120px !important;
    height: 120px;
    text-align: center;
    display: inline-flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    white-space: break-spaces;
    cursor: pointer;
    padding: 10px;
    position: relative;
}

.dataPlugin-box span {
    line-height: 16px;
    font-size: 12px;
}

.data-search, .sources-data-search {
    min-height: 1.5em !important;
    border: 1px solid #455066;
    border-radius: 5px;
}

.dataPlugin-search {
    display: inline-block;
    position: absolute;
    left: 5px;
    color: rgb(142, 142, 142);
    cursor: pointer;
    line-height: 0;
}

.searchImg {
    width: 20px;
    height: 20px;
    filter: invert(29%) sepia(7%) saturate(2359%) hue-rotate(184deg) brightness(91%) contrast(81%);
}

.searchImg-rotate {
    transform: rotate(45deg);
}

.supported-plugin {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 5px;
    top: 5px;
}

.actions-menu {
    width: 20px;
    height: 20px;
    position: absolute !important;
    right: 5px;
    top: 5px;
}

.selected-plugin {
    border: 1px solid #455066;
}

.plugin-img {
    max-width: 70px;
    max-height: 60px;    
    margin-bottom: 5px;
}

.properties-wrapper:nth-last-match(div) {
    background: orange;
}

.dataPlugin-properties {
    /* margin: 5px auto; */
    width: 100%;
    padding: 0 5px 0 0;
}

.step-header {
    display: flex;
    align-items: center;
    padding: 10px 3px;
}

.step-identicator {
    width: 25px;
    height: 25px;
    background-color: #424f74;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
}

.step-title {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.5;
    font-size: 15px;
}

.step-data-wrapper {
    padding-left: 15px;
    padding-bottom: 10px;
}

.step-data {
    border-left: 1px solid #424f74;
    padding-left: 20px;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.step-data .step-header {
    padding: unset
}

.clear-border {
    border: unset;
}

.expand-arrow {
    width: 15px;
    margin-right: 10px;
    transition: 0.4s;
}

.expand-arrow.expanded {
    transform: rotate(90deg);
}

.help-button {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 2px;
    top: 2px;
    transform: scale(0.85);
    border-radius: 50%;
}
  
.help-button-img{
    max-width: none !important;
    padding: 0 !important;
    filter: none !important;
}

  @keyframes glowing {
    0% {
      background-color: #454f66;
      box-shadow: 0 0 5px #454f66;
    }
    50% {
      background-color: #454f66;
      box-shadow: 0 0 20px #454f66;
    }
    100% {
      background-color: #454f66;
      box-shadow: 0 0 5px #454f66;
    }
  }

  .help-button:hover{
      animation: glowing 1500ms 0ms;
  }

  .help-info {
    width: 100%;
    padding: 0 20px;
  }
  
  div.MuiPaper-root.MuiAlert-root.MuiAlert-standardError.MuiPaper-elevation0{
      margin-bottom: 1rem;
  }

  .no-display{
      display: none;
  }

  .vl-help{
    border-left: 1px solid #ddd;
    height: 89%;
    position: absolute;
    top: 2.5rem;
    right: 0;
  }

.no-padding-right{
    padding-right: 0;
}

.no-padding-left{
    padding-left: 0;
} 
